import React, { useState } from "react"
import { Grid, Tab, Tabs, Tooltip } from "@material-ui/core"
import { ActionDialog } from "@/components"

import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/FlowReports/style"
import {
	InsertChartOutlinedOutlined as ChartIcon
} from "@material-ui/icons"

import colors from "@/styles/colors"
import EntrantFlowReport from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/EntrantFlowReport"

type ReportTabType = "entrants" | "webhook"

type ReportTab = {
	title: string
	type: ReportTabType
}

const REPORT_TABS: ReportTab[] = [
	{
		title: "Entrantes no Bot",
		type: "entrants"
	}
]

type EntrantsReportProps = {
	instanceId: number,
	chatBotFlowId: number,
}

const FlowReports: React.FC<EntrantsReportProps> = (props) => {
	const {
		instanceId,
		chatBotFlowId
	} = props

	const classes = useStyles()

	const [openDialog, setOpenDialog] = useState<boolean>(false)
	const [currentReportTabType, setCurrentReportTabType] = useState<ReportTabType>(REPORT_TABS[0]?.type)

	const handleOpenDialog = async () => {
		setOpenDialog(true)
	}

	const handleCloseDialog = () => {
		setOpenDialog(false)
	}

	const handleChangeCurrentReportTabType = (type: ReportTabType): void => {
		setCurrentReportTabType(type)
	}

	return (
		<>
			<Grid
				onClick={handleOpenDialog}
				className={`${classes.button}`}
			>
				<Tooltip
					title={"Relatórios"}
				>
					<ChartIcon fontSize="small" style={{ color: colors.grayScale[11] }} />
				</Tooltip>
			</Grid>

			<ActionDialog
				title={"RELATÓRIOS"}
				hideCloseButton={true}
				openDialog={openDialog}
				onClose={handleCloseDialog}
				maxWidth="lg"
				fullWidth
			>
				<Grid container direction="column" spacing={2}>
					<Grid item>
						<Tabs
							color="primary"
							value={currentReportTabType}
							className={classes.tabs}
							onChange={(_, value) => handleChangeCurrentReportTabType(value)}
							classes={{
								indicator: classes.tabIndicator
							}}
						>
							{REPORT_TABS.map((tab) => (
								<Tab
									key={tab.title}
									label={tab.title}
									value={tab.type}
									className={classes.tab}
								/>
							))}
						</Tabs>
					</Grid>

					<Grid item>
						{currentReportTabType === "entrants" && (
							<EntrantFlowReport
								instanceId={instanceId}
								chatBotFlowId={chatBotFlowId}
								opened={openDialog}
							/>
						)}
					</Grid>
				</Grid>

			</ActionDialog>
		</>
	)
}

export default FlowReports
