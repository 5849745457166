import React, { useState, useEffect } from "react"
import { useParams, Link, useHistory } from "react-router-dom"
import { ReactFlowProvider } from "reactflow"
import {
	Grid,
	Typography,
	TextField,
	Tooltip,
	Button,
	IconButton
} from "@material-ui/core"

import {
	ArrowBackIos as GoBackIcon,
	Schedule as LastChangeIcon,
	Delete as DeleteIcon,
	Edit as EditIcon,
	ErrorOutline as ValidationFlowIcon
} from "@material-ui/icons"

import {
	Divider,
	InputDialog,
	Loading,
	MoreOptionsMenu,
	Notification,
	PopConfirm,
	ClosePagePrompt
} from "@/components"

import { addNotificationGlobalStyles, removeNotificationGlobalStyles } from "@/components/Notification"

import ActiveFlowReport from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/ActiveFlowReport"
import ValidateFlow from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/ValidateFlow"
import { FlowSwitch } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/FlowSwitch"
import SaveFlowButton from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/SaveFlowButton"

import colors from "@/styles/colors"
import useStyles from "@/pages/Admin/Flow/FlowConstructor/styles"

import useDidMount from "@/hooks/useDidMount"
import { ErrorType } from "@/hooks/useValidation"
import useHotjar from "@/hooks/useHotjar"
import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"
import { useGlobalStateStore } from "@/store/GlobalState"
import useChatBotFlowSave from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowSave"

import ChatBotFlowConstructorStateProvider from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/stores/ChatBotFlowConstructorState"
import GlobalItemSelectionStateProvider from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/stores/GlobalItemSelectionState"

import FlowEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor"

import ChatBotFlowConstructorSkeleton from "@/skeletons/Admin/ChatBotFlowConstructorSkeleton"

import { ShortFlow } from "@/protocols/chatBotConstructor"

import ChatBotFlowService from "@/services/ChatBotFlow"
import ChatBotConstructorService from "@/services/ChatBotConstructor"
import ErrorHandlerService from "@/services/ErrorHandler"

import { fullDatetime } from "@/utils/time"
import NoteDialog from "@/components/NoteDialog"
import { NoteAdd, NoteView } from "@/assets/icons"
import FlowReports from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/FlowReports"

const FlowConstructor: React.FC = () => {
	const classes = useStyles()
	const history = useHistory()
	const globalStateStore = useGlobalStateStore()
	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()

	const [loading, setLoading] = useState(true)
	const [tooltipMessage, setTooltipMessage] = useState("Só é possível salvar após efetuar alguma alteração no bot.")

	const {
		handleToggleActivation
	} = useChatBotFlowSave()

	const hotjar = useHotjar()
	hotjar.setupHotjar()

	const isShowAllChatBotVersions = globalStateStore.user.botVersions.willShow

	const loadInitialData = async () => {
		await chatBotFlowConstructorStore.load()

		setLoading(false)
	}

	const handleUpdateData = async (chatBotFlow: Partial<ShortFlow>): Promise<void> => {
		await ChatBotFlowService.update(chatBotFlowConstructorStore.flowData.id, chatBotFlow as ShortFlow)

		chatBotFlowConstructorStore.changeChatBotFlow({
			...chatBotFlow,
			updated_at: new Date().toISOString()
		})
	}

	const handleDelete = () => {
		PopConfirm.open({
			title: "Excluir Bot",
			description: "Tem certeza? Essa ação é irreversível.",
			onConfirm: async () => {
				try {
					await ChatBotFlowService.delete(chatBotFlowConstructorStore.flowData.id)
					Notification.success({ message: "Bot excluído com sucesso!" })
					history.push("/admin/flow")
				} catch (error) {
					ErrorHandlerService.handle(error as ErrorType)
					Notification.error({ message: "Não foi possível excluir o bot." })
				}
			},
			confirmButtonText: "EXCLUIR"
		})
	}

	const handleEdit = async () => {
		InputDialog.open({
			title: "Alterar nome do Bot",
			onOk: async (name) => {
				try {
					await handleUpdateData({ name: name as string })
					Notification.success({ message: "Nome do bot alterado com sucesso!" })
					return true
				} catch (error) {
					ErrorHandlerService.handle(error as ErrorType)
					Notification.error({ message: "Não foi possível alterar o nome do bot." })
					return false
				}
			},
			initialValue: chatBotFlowConstructorStore.flowData.name || "",
			fullWidth: true,
			customInputElement: (
				<TextField
					placeholder="Nome do Bot"
					variant="outlined"
					color="primary"
					fullWidth
				/>
			)
		})
	}

	useDidMount(() => {
		loadInitialData()
	})

	/**
	 * WARNING:
	 * - That's a workaround to change 'Notification' component
	 * style globally inside this page, since we need to make it
	 * positions belows navbar to avoid UX problems.
	 * - Since this component is used in a lot of places inside
	 * this page, it is better to change its behavior globally
	 * instead of passing props for every component.
	 * - When page is mounted, we add the global styles and
	 * when the page is unmounted, we remove the global styles.
	 */
	useEffect(() => {
		addNotificationGlobalStyles({ marginTop: "32px" })

		return () => removeNotificationGlobalStyles()
	}, [])

	return (
		<Grid
			container
			direction="column"
			className={classes.pageContainer}
		>
			<ClosePagePrompt
				activated={chatBotFlowConstructorStore.changed}
				message="Você ainda não salvou suas alterações. Tem certeza que deseja sair?"
			/>

			<Loading
				loading={loading}
				customLoadingElement={<ChatBotFlowConstructorSkeleton />}
			>
				<>
					<Grid
						container
					>
						<Grid
							container
							alignItems="center"
							justify="space-between"
							className={classes.headerContainer}
						>
							<Grid
								item
							>
								<Grid
									container
									alignItems="center"
									justifyContent="flex-start"
									className={classes.headerContent}
									spacing={2}
								>
									<Grid
										item
									>
										<Button
											component={Link}
											to={isShowAllChatBotVersions ? "/admin/bots" : "/admin/flow"}
											startIcon={<GoBackIcon />}
											className={classes.backButton}
										>
											Voltar
										</Button>
									</Grid>

									<Grid
										item
									>
										<Typography
											color="textPrimary"
										>
											<strong>Bot:</strong> {chatBotFlowConstructorStore.flowData?.name}
										</Typography>
									</Grid>

									<Grid
										item
									>
										<IconButton
											onClick={() => {
												NoteDialog.open({
													context: "chat-bot",
													note: {
														id: chatBotFlowConstructorStore.chatBotNotes?.chat_bot_flow_note?.id,
														chat_bot_flow_id: chatBotFlowConstructorStore.flowData?.id,
														content: chatBotFlowConstructorStore.chatBotNotes?.chat_bot_flow_note?.content
													},
													onCreateOrEditCallback: async (note) => {
														chatBotFlowConstructorStore?.setChatBotNotes?.({
															...chatBotFlowConstructorStore.chatBotNotes,
															...{
																chat_bot_flow_note: note
															}
														})
													},
													onDeleteCallback: async () => {
														chatBotFlowConstructorStore?.setChatBotNotes?.({
															...chatBotFlowConstructorStore.chatBotNotes,
															...{
																chat_bot_flow_note: undefined
															}
														})
													}
												})
											}}
										>
											{!chatBotFlowConstructorStore.chatBotNotes?.chat_bot_flow_note?.id ? <NoteAdd opacity={0.65}/> : <NoteView opacity={0.65}/>}
										</IconButton>
									</Grid>
								</Grid>
							</Grid>

							<Grid
								item
								className={classes.headerContent}
							>
								<Grid
									container
									alignItems="center"
									justify="flex-end"
								>
									<Grid item>
										<FlowReports
											instanceId={globalStateStore.instance.instance_id}
											chatBotFlowId={chatBotFlowConstructorStore.flowData?.id}
										/>
									</Grid>

									<Divider orientation="vertical" size={1} />

									<Grid item>
										<ValidateFlow>
											<Grid
												className={classes.validationButton}
											>
												<ValidationFlowIcon fontSize="small" style={{ color: colors.grayScale[11] }} />
												<Typography className={classes.validationButtonText}>
													{ chatBotFlowConstructorStore.validationErrorsCount }
												</Typography>
											</Grid>
										</ValidateFlow>
									</Grid>

									<Divider orientation="vertical" size={1} />

									<Grid item>
										<ActiveFlowReport
											activeInboxChannelChatFlows={chatBotFlowConstructorStore.flowData.active_inbox_channel_chat_flows}
											changeChatBotFlow={chatBotFlowConstructorStore.changeChatBotFlow}
											chatBotFlowId={chatBotFlowConstructorStore.flowData.id}
											tooltipTitle="Contatos que estão nesse fluxo atualmente"
											buttonTitle="Excluir todos contatos do fluxo"
										/>
									</Grid>

									<Divider orientation="vertical" size={1} />

									{chatBotFlowConstructorStore.flowData?.updated_at && (
										<Grid
											item
										>
											<Grid
												container
												alignItems="center"
											>
												<LastChangeIcon
													className={classes.lastChangeIcon}
													fontSize="small"
												/>

												<Divider orientation="vertical" size={1} />

												<Typography
													variant="caption"
													className={classes.lastChangeText}
												>
													Última alteração: {fullDatetime(new Date(chatBotFlowConstructorStore.flowData?.updated_at))}
												</Typography>
											</Grid>
										</Grid>
									)}

									<Divider orientation="vertical" size={3} />

									<Grid
										item
									>
										<Tooltip
											title={tooltipMessage}
										>
											<Grid
												item
												onMouseMove={() => {
													if (!chatBotFlowConstructorStore.changed) {
														setTooltipMessage("O botão está desabilitado porque nenhuma alteração foi feita no bot.")
													}
												}}
												onMouseLeave={() => setTooltipMessage("")}
											>
												<SaveFlowButton />
											</Grid>
										</Tooltip>
									</Grid>

									<Divider orientation="vertical" size={2} />

									<Grid
										item
									>
										<FlowSwitch
											checked={chatBotFlowConstructorStore.flowData?.active}
											onChange={({ target }) => handleToggleActivation(target.checked)}
										/>
									</Grid>

									<Grid
										item
									>
										<MoreOptionsMenu
											closeOnClick={true}
											options={[
												{
													title: "Alterar",
													icon: <EditIcon/>,
													showIcon: true,
													onClick: handleEdit
												},
												{
													title: "Excluir",
													icon: <DeleteIcon />,
													color: "danger",
													showIcon: true,
													onClick: handleDelete
												}
											]}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<Grid
						container
						className={classes.flowContainer}
					>
						<FlowEditor />
					</Grid>
				</>
			</Loading>
		</Grid>
	)
}

const ChatBotFlowConstructorWithContext = () => {
	const { chatBotFlowId } = useParams<{ chatBotFlowId?: string }>()

	return (
		<GlobalItemSelectionStateProvider>
			<ReactFlowProvider>
				<ChatBotFlowConstructorStateProvider
					getFlowData={async () => await ChatBotConstructorService.retrieveFlow(Number(chatBotFlowId))}
					isPreview={false}
					flowId={Number(chatBotFlowId)}
				>
					<FlowConstructor />
				</ChatBotFlowConstructorStateProvider>
			</ReactFlowProvider>
		</GlobalItemSelectionStateProvider>
	)
}

export default ChatBotFlowConstructorWithContext
