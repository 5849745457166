import React, { useEffect, useState } from "react"

import {
	Accordion,
	AccordionDetails,
	AccordionSummary, Button,
	Chip, CircularProgress,
	FormControl,
	FormLabel,
	Grid,
	TextField,
	Typography
} from "@material-ui/core"
import {
	NavigateNext as OpenAccordionIcon,
	WhatsApp as WhatsAppIcon
} from "@material-ui/icons"

import {
	Notification,
	ActionDialog,
	CustomLink,
	AlertContainer,
	Loading
} from "@/components"
import { AlertType } from "@/components/AlertContainer"

import {
	IChannel,
	WABAChannelStatus
} from "@/protocols/channel"
import { WABAAppData, WABAChannelExtraData, WABAChannelSettings, WABAComputedStatus } from "@/@integrations/WABA/protocols/channel"

import {
	getWABAChannelTitle,
	getWABAComputedStatus,
	getWABAStatusHelperMessages,
	getWABAStatusIcon,
	getWABAStatusTitle,
	getWABAStatusStyleProps
} from "@/@integrations/WABA/utils/channel"
import { getWABAErrorMessage } from "@/@integrations/WABA/utils/errorsText"
import { WABALinks } from "@/utils/link"

import { useGlobalStateStore } from "@/store/GlobalState"
import useWABAConnection from "@/@integrations/WABA/hooks/useWABAConnection"
import useValidation, { ErrorType } from "@/hooks/useValidation"

import useStyles from "@/@integrations/WABA/components/WABAConnectionFlow/styles"
import ErrorHandlerService from "@/services/ErrorHandler"
import WABAChannelService from "@/@integrations/WABA/services/WABAChannelService"
import useDidMount from "@/hooks/useDidMount"
import newColors from "@/styles/newColors"
import WABAChannelWalletBalanceSkeleton from "@/@integrations/WABA/skeletons/WABAChannelWalletBalanceSkeleton"
import HardCoded from "@/services/HardCoded"

const WABAConnectionFlow = () => {
	const globalStateStore = useGlobalStateStore()
	const wabaConnection = useWABAConnection()
	const {
		validation,
		triggerValidation,
		clearAllValidations
	} = useValidation()

	const inboxChannel = globalStateStore.currentChannel as IChannel
	const inboxChannelStatus = inboxChannel.status as WABAChannelStatus
	const computedStatus = getWABAComputedStatus(inboxChannelStatus)

	const canConfigureWABAChannelApi = HardCoded.checkFeatureFlag("canConfigureWABAApiChannel") && computedStatus === "disconnected"
	const canShowStatusHelperMessagesDialog = (["restricted", "banned"] as WABAComputedStatus[]).includes(computedStatus)

	const [expanded, setExpanded] = useState<boolean>(false)
	const [brokerSettings, setBrokerSettings] = useState<WABAChannelSettings>({ appName: "", apiKey: "" })
	const [appData, setAppData] = useState<WABAAppData>()
	const [loading, setLoading] = useState<boolean>(false)

	const classes = useStyles()

	const handleSetBrokerSettings = (settings: Partial<WABAChannelSettings>) => {
		const updatedSettings = {
			...brokerSettings,
			...settings
		}
		setBrokerSettings(updatedSettings)
		clearAllValidations()
	}

	const handleSetupWABAConnection = async (): Promise<void> => {
		setLoading(true)
		try {
			await wabaConnection.setupChannel({
				...brokerSettings,
				inboxChannelId: inboxChannel?.id as number
			})
		} catch (error) {
			triggerValidation(error as ErrorType)

			const errorMessage = getWABAErrorMessage(error as ErrorType)

			if (errorMessage) {
				Notification.error({
					message: errorMessage
				})
			}
		}

		setLoading(false)
	}

	const handleShowStatusHelperMessagesDialog = () => {
		const wabaStatusHelperMessages = getWABAStatusHelperMessages(inboxChannelStatus, inboxChannel.extraData as WABAChannelExtraData)

		const wabaComputedStatusToAlertType: Record<WABAComputedStatus, AlertType> = {
			banned: "error",
			connected: "info",
			disconnected: "info",
			restricted: "warning"
		}

		const alertType = wabaComputedStatusToAlertType[computedStatus]
		const title = getWABAStatusTitle(inboxChannelStatus)

		const isThereAnyStatusMessage = wabaStatusHelperMessages.length > 0

		ActionDialog.open({
			title,
			cancelText: "Voltar",
			openDialog: true,
			forcedRender: true,
			children: (
				<Grid
					container
					direction="column"
					justifyContent="center"
					spacing={2}
				>
					{isThereAnyStatusMessage && (
						<Grid
							item
						>
							<AlertContainer
								alertType={alertType}
							>
								<ul>
									{wabaStatusHelperMessages.map((statusHelperMessage, index) => (
										<li key={index}>
											<Typography>
												{statusHelperMessage}
											</Typography>
										</li>
									))}
								</ul>
							</AlertContainer>
						</Grid>
					)}

					<Grid
						item
					>
						<CustomLink
							target="_blank"
							href={WABALinks.howToResolveBannedOrRestrictedWABAAccount}
							title="Saiba como resolver"
						/>
					</Grid>
				</Grid>
			)
		})
	}

	const getStatusChip = () => {
		const statusStyleProps = getWABAStatusStyleProps(inboxChannelStatus)
		const statusIcon = getWABAStatusIcon(inboxChannelStatus)
		const statusTitle = getWABAStatusTitle(inboxChannelStatus, inboxChannel.broker_extra_data?.broker_type)

		return (
			<Chip
				icon={statusIcon}
				className={classes.statusChip}
				label={statusTitle}
				style={{
					...statusStyleProps
				}}
				onClick={canShowStatusHelperMessagesDialog ? handleShowStatusHelperMessagesDialog : undefined}
			/>
		)
	}

	const getWalletBalance = () => {
		if (appData?.walletBalance) {
			return (<Typography style={{ color: newColors.purple[600] }}>
				Créditos: {`${appData?.walletBalance?.currentBalance} ${appData?.walletBalance?.currency}`}
			</Typography>)
		} else {
			return (<Typography style={{ color: newColors.purple[600] }}>
				Não foi possível carregar os créditos
			</Typography>)
		}
	}

	const getAppData = async (): Promise<void> => {
		setLoading(true)
		try {
			const appInfo = await WABAChannelService.getAppData(inboxChannel?.id)
			setAppData(appInfo)
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
		}
		setLoading(false)
	}

	useEffect(() => {
		if (!canConfigureWABAChannelApi) {
			setExpanded(false)
		}
	}, [canConfigureWABAChannelApi])

	useDidMount(() => {
		getAppData()
	})

	return (
		<Accordion
			classes={{
				root: classes.root
			}}
			expanded={expanded}
		>
			<AccordionSummary
				classes={{
					expanded: classes.expandedAccordion
				}}
				{...(canConfigureWABAChannelApi && {
					expandIcon: <OpenAccordionIcon />,
					IconButtonProps: {
						onClick: () => {
							if (canConfigureWABAChannelApi) {
								setExpanded(!expanded)
							}
						}
					}
				})}
				className={classes.accordionSummary}
			>
				<Grid
					container
					alignItems="center"
					justifyContent="space-between"
				>
					<Grid
						item
					>
						<Grid
							container
							alignItems="center"
							style={{
								gap: "12px"
							}}
						>
							<Grid
								container
								alignItems="center"
								justifyContent="center"
								className={classes.wabaIconContainer}
							>
								<WhatsAppIcon
									className={classes.whatsappIcon}
								/>
							</Grid>

							<Typography
								variant="body1"
								className={classes.channelInfoText}
							>
								{getWABAChannelTitle(inboxChannelStatus, {
									...inboxChannel?.extraData,
									user: {
										phoneNumber: inboxChannel?.extraData?.user?.phoneNumber as string,
										name: inboxChannel?.extraData?.user?.name || globalStateStore.user.name
									}
								})}
							</Typography>
						</Grid>
					</Grid>

					<Grid item>
						<Loading
							loading={loading}
							customLoadingElement={<WABAChannelWalletBalanceSkeleton/>}
						>
							{getWalletBalance()}
						</Loading>
					</Grid>

					<Grid item>
						<Grid
							container
							alignItems="center"
						>
							{getStatusChip()}
						</Grid>
					</Grid>
				</Grid>
			</AccordionSummary>
			<AccordionDetails>
				<Grid
					container
					direction="column"
					spacing={2}
				>
					<Grid
						item
					>
						<Grid
							container
							justifyContent="space-between"
							spacing={2}
						>
							<Grid
								item
								xs
							>
								<FormControl fullWidth focused={false}>
									<FormLabel
										className={classes.formLabel}
									>
										Nome do App da Gupshup
									</FormLabel>
									<TextField
										id="gupshup-app-name-input"
										type="text"
										placeholder="Escolha o mesmo nome do app cadastrado na Gupshup"
										variant="outlined"
										onChange={
											(event: React.ChangeEvent<HTMLInputElement>) => handleSetBrokerSettings({ appName: event.target.value })
										}
										helperText={validation.app_name}
										error={!!validation.app_name}
									/>
								</FormControl>
							</Grid>

							<Grid
								item
								xs
							>
								<FormControl fullWidth focused={false}>
									<FormLabel
										className={classes.formLabel}
									>
										API Key da GupShup
									</FormLabel>
									<TextField
										id="gupshup-api-key-input"
										type="text"
										placeholder="0000000000000000"
										variant="outlined"
										onChange={
											(event: React.ChangeEvent<HTMLInputElement>) => handleSetBrokerSettings({ apiKey: event.target.value })
										}
										helperText={validation.api_key}
										error={!!validation.api_key}
									/>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>

					<Grid
						item
					>
						<Button
							color="primary"
							variant="contained"
							endIcon={loading && <CircularProgress size={20} />}
							fullWidth
							className={classes.saveButton}
							onClick={handleSetupWABAConnection}
							disabled={loading}
						>
							Salvar
						</Button>
					</Grid>
				</Grid>
			</AccordionDetails>
		</Accordion>
	)
}

export default WABAConnectionFlow
