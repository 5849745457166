import React, { useState, useImperativeHandle, useRef, forwardRef, useEffect } from "react"
import { Editor, EditorState, CompositeDecorator, Modifier, ContentState, DraftDecorator } from "draft-js"
import useStyles from "@/components/TextEditor/styles"
import { regexList } from "@/utils/regex"

const DEFAULT_MAX_LENGTH = 4000

type EntityType = "VARIABLE" | "TEXT"

type DraftEditorManipulationOption = "handled" | "not-handled"

type TextModifierData = {
	content: ContentState
	action: "insert-characters"
}

type TextEditorProps = {
	onChanged?: (value: string) => void
	maxLength?: number
}

export type TextEditorInputHandler = {
	addEntityByCursor: (textType: EntityType, text: string) => void
	addText: (text: string) => void
}

type Token = {
	tokenType: EntityType
	startIndex: number
	endIndex: number
}

type TokenMatch = {
	match: RegExpExecArray
	type: EntityType
}

type EntityMatcherType = {
	type: EntityType, regex: RegExp
}

type EntityManagerType = {
	decorator: DraftDecorator,
	matcher: EntityMatcherType,
	strategy: (editorState: EditorState, text: string) => ContentState
	modifier: (ditorState: EditorState, text: string, entityType?: EntityType) => ContentState
	contentModifier: (content: string) => string
}

const TextEditor: React.ForwardRefRenderFunction<TextEditorInputHandler, TextEditorProps> = (props, ref) => {
	const { onChanged, maxLength } = props
	const editorRef = useRef<Editor>(null)
	const classes = useStyles()
	const maxTextLength = maxLength || DEFAULT_MAX_LENGTH

	const replaceVariableWithEntities = (
		text: string,
		callback: (start: number, end: number) => void
	): void => {
		const validateVariableRegex = regexList.is_variable
		text.replace(validateVariableRegex, (match, _, index) => {
			callback(index, index + match.length)
			return match
		})
	}

	const entityTypeToEntityManager: Record<EntityType, EntityManagerType> = {
		VARIABLE: {
			decorator: {
				strategy: (contentBlock, callback) => {
					const text = contentBlock.getText()
					replaceVariableWithEntities(text, callback)
				},
				component: ({ children }) => (
					<span className={classes.variable} contentEditable={false}>
						{children}
					</span>
				)
			},
			matcher: { type: "VARIABLE", regex: regexList.is_variable },
			contentModifier: (content: string) => {
				return `{{${content}}}`
			},
			modifier: (editorState: EditorState, text: string) => {
				const contentState = editorState.getCurrentContent()
				const selection = editorState.getSelection()
				const contentStateWithEntity = contentState.createEntity("VARIABLE", "IMMUTABLE", { name: text })
				return Modifier.insertText(
					contentStateWithEntity,
					selection,
					text,
					undefined,
					contentStateWithEntity.getLastCreatedEntityKey()
				)
			},
			strategy: (editorState: EditorState, text: string) => {
				const contentState = editorState.getCurrentContent()
				const selection = editorState.getSelection()
				const contentStateWithEntity = contentState.createEntity("VARIABLE", "IMMUTABLE", { name: text })
				return Modifier.insertText(
					contentStateWithEntity,
					selection,
					text,
					undefined,
					contentStateWithEntity.getLastCreatedEntityKey()
				)
			}

		},
		TEXT: {
			decorator: {
				strategy: (contentBlock, callback) => {
					const text = contentBlock.getText()
					replaceVariableWithEntities(text, callback)
				},
				component: ({ children }) => (
					<span>
						{children}
					</span>
				)
			},
			matcher: { type: "TEXT", regex: regexList.is_text },
			contentModifier: (content: string) => {
				return content
			},
			modifier: (editorState: EditorState, text: string): ContentState => {
				const contentState = editorState.getCurrentContent()
				const selection = editorState.getSelection()

				return Modifier.insertText(contentState, selection, text)
			},
			strategy: (editorState: EditorState, text: string) => {
				const contentState = editorState.getCurrentContent()
				const selection = editorState.getSelection()
				return Modifier.insertText(contentState, selection, text)
			}
		}
	}

	const findClosestEntityMatch = (
		text: string,
		startIndex: number
	): TokenMatch | null => {
		let closestMatchEntity: RegExpExecArray | null = null
		let closestMatchType: EntityType | null = null

		const entityMatchers = Object.values(entityTypeToEntityManager).map(({ matcher }) => matcher)

		entityMatchers.forEach(({ type, regex }) => {
			regex.lastIndex = startIndex
			const matchedEntity = regex.exec(text)
			const isNewClosestMatch = matchedEntity && (closestMatchEntity === null || matchedEntity.index < closestMatchEntity.index)
			if (isNewClosestMatch) {
				closestMatchEntity = matchedEntity
				closestMatchType = type
			}
		})

		if (closestMatchEntity && closestMatchType) {
			return { match: closestMatchEntity, type: closestMatchType }
		}

		return null
	}

	const getEntitiesFromText = (inputText: string): Token[] => {
		const entities: Token[] = []
		let currentPosition = 0

		while (currentPosition < inputText.length) {
			const nextMatch = findClosestEntityMatch(inputText, currentPosition)

			if (nextMatch) {
				const { match, type } = nextMatch
				const isMatchAfterCurrentPosition = currentPosition < match.index

				if (isMatchAfterCurrentPosition) {
					entities.push({
						tokenType: "TEXT",
						startIndex: currentPosition,
						endIndex: match.index
					})
				}

				entities.push({
					tokenType: type,
					startIndex: match.index,
					endIndex: match.index + match[0].length
				})
				currentPosition = match.index + match[0].length
			} else {
				entities.push({
					tokenType: "TEXT",
					startIndex: currentPosition,
					endIndex: inputText.length
				})
				break
			}
		}

		return entities
	}

	const applyEntityStrategy = (editorState: EditorState, type: EntityType, text: string) => {
		const entityManager = entityTypeToEntityManager[type]
		const newContentState = entityManager.strategy(editorState, text)

		return EditorState.push(editorState, newContentState, "insert-characters")
	}
	const decorator = new CompositeDecorator(Object.values(entityTypeToEntityManager).map(({ decorator }) => decorator))

	const [editorState, setEditorState] = useState(() => EditorState.createEmpty(decorator))

	const adaptEntityModifier = (editorState: EditorState, text: string, entityType: EntityType): TextModifierData => {
		const entityManager = entityTypeToEntityManager[entityType]

		const entityContent = entityManager.modifier(editorState, text)

		return { content: entityContent, action: "insert-characters" }
	}

	const getEditorStateEntity = (newEditorState: EditorState, text: string, entityType: EntityType): EditorState => {
		const modifier = adaptEntityModifier(newEditorState, text, entityType)

		return EditorState.push(newEditorState, modifier.content, modifier.action)
	}

	const isAbleToModifyContent = (textLength: number): DraftEditorManipulationOption => {
		const isReached = textLength >= maxTextLength
		return isReached ? "handled" : "not-handled"
	}

	const addText = (text: string) => {
		let newEditorState = editorState
		const entities = getEntitiesFromText(text)

		entities.forEach((token: Token) => {
			const value = text.slice(token.startIndex, token.endIndex)
			newEditorState = applyEntityStrategy(newEditorState, token.tokenType, value)
		})

		setEditorState(newEditorState)
	}

	const handleAddEntityByCursor = (entityType: EntityType, content: string) => {
		const entityManager = entityTypeToEntityManager[entityType]
		const entity = getEditorStateEntity(editorState, entityManager.contentModifier(content), entityType)

		setEditorState(entity)
	}

	const handleBeforeInput = (chars: string, editorState: EditorState): DraftEditorManipulationOption => {
		const currentContentLength = editorState.getCurrentContent().getPlainText("").length
		return isAbleToModifyContent(currentContentLength)
	}

	const handlePastedText = (pastedText: string, html: string | undefined, editorState: EditorState): DraftEditorManipulationOption => {
		const currentContentLength = editorState.getCurrentContent().getPlainText("").length + pastedText.length
		return isAbleToModifyContent(currentContentLength)
	}

	useEffect(() => {
		const contentState = editorState.getCurrentContent()
		const content = contentState.getPlainText()
		onChanged?.(content)

		if (!contentState.hasText()) {
			onChanged?.("")
		}
	}, [editorState])

	useImperativeHandle(ref, () => ({
		addEntityByCursor: (type, text) => handleAddEntityByCursor(type, text),
		addText: (text) => addText(text)
	}))

	return (
		<div className={classes.container}>
			<Editor
				editorState={editorState}
				onChange={setEditorState}
				ref={editorRef}
				handleBeforeInput={handleBeforeInput}
				handlePastedText={handlePastedText}
			/>
		</div>
	)
}

export default forwardRef(TextEditor)
