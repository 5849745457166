import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	container: {
		backgroundColor: colors.unrelated.F6F6F6,
		boxShadow: "none",
		margin: "0px !important"
	},
	box: {
		maxWidth: "415px"
	},
	dateContainer: {
		flex: 1,
		"& input": {
			fontSize: "14px",
			height: "19px",
			marginLeft: "4px"
		},
		"& button": {
			width: "30px",
			height: "40px"
		},
		"& div": {
			paddingLeft: "0px",
			marginRight: "0px"
		}
	},
	select: {
		height: "40px",
		"&:focus": {
			backgroundColor: "transparent"
		}
	}

})

export default useStyles
