import LogService from "@/services/Log"

class ErrorHandlerService {
	static handle (error: Error): void {
		LogService.error(error)

		if (process.env.NODE_ENV === "development") {
			console.error("[ERROR]: ", error)
		}
	}
}

export default ErrorHandlerService
