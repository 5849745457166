import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid, makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	skeleton: {
		transform: "scale(1)",
		borderRadius: 8
	},
	container: {
		backgroundColor: colors.unrelated.F6F6F6,
		padding: theme.spacing(1, 2, 2, 2)
	}
}))

const WABAChannelWalletBalanceSkeleton = () => {
	const classes = useStyles()

	return (
		<Grid container spacing={2}>
			<Skeleton className={classes.skeleton} height={25} width={150} />
		</Grid>
	)
}

export default WABAChannelWalletBalanceSkeleton
