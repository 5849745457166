import ApiService from "@/services/Api"
import { WABAAppData } from "@/@integrations/WABA/protocols/channel"

class WABAChannelService {
	async getAppData (channelId?: number): Promise<WABAAppData> {
		const result = await ApiService.get(`/waba/channel/${channelId}/app-data`)

		return result.data
	}
}

export default new WABAChannelService()
