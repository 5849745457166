import newColors from "@/styles/newColors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	container: {
		border: "1px solid",
		borderRadius: 3,
		borderColor: newColors.grey[300],
		padding: "10px",
		minHeight: 50,
		width: "100%",
		"& .public-DraftStyleDefault-block": {
			margin: "2px 0px"
		}
	},
	editorWrapper: {
		flex: 1,
		width: "100%",
		overflow: "auto",
		borderRadius: 4,
		padding: 8
	},
	variable: {
		color: newColors.grey[0],
		backgroundColor: newColors.purple[500],
		display: "inline-flex",
		width: "fit-content",
		borderRadius: 4
	}
})

export default useStyles
