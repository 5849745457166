import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	container: {
		fontWeight: "bold",
		cursor: "pointer",
		backgroundColor: colors.unrelated.C3D60D,
		color: colors.grayScale[11]
	}
})

export default useStyles
