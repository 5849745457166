import React, { useState } from "react"
import DateFnsUtils from "@date-io/date-fns"
import { ptBR } from "date-fns/locale"
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers"
import Autocomplete from "@material-ui/lab/Autocomplete"
import {
	Grid,
	Typography,
	TextField,
	Chip,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	InputAdornment,
	Tooltip,
	CircularProgress
} from "@material-ui/core"
import {
	FilterList as FilterIcon,
	Group as AttendantFilterIcon,
	LocalOffer as TagFilterIcon,
	Forum as ChatTypeFilterIcon,
	Cancel as ClearFilterIcon,
	Message as MessageFilterIcon
} from "@material-ui/icons"

import { useChatGlobalStateStore, ChatListFilter as IChatListFilter, MessagesFilter } from "@/store/ChatGlobalState"
import { useGlobalStateStore } from "@/store/GlobalState"

import useCustomMemo from "@/hooks/useCustomMemo"
import useChat from "@/hooks/useChat"
import useSubscriptionLimits from "@/hooks/useSubscriptionLimits"

import { Divider } from "@/components"

import { convertRGBAObjectToString } from "@/utils/color"
import { deviceIsMobile } from "@/utils/checkDevice"
import { promiseWithTimeout } from "@/utils/time"

import { IChatType } from "@/protocols/channel"

import TeamService from "@/services/Team"

import colors from "@/styles/colors"
import useCustomStyles from "@/styles/custom"
import useStyles from "@/pages/Attendance/Chat/ChatListPanel/ChatListFilter/styles"

import { TABS } from "@/pages/Attendance/Chat/ChatListPanel/ChatListTab"

type ChatTypeFilter = {
	type: IChatType
	name: string
	color: string
}

const CHAT_TYPE_FILTERS: ChatTypeFilter[] = [
	{
		type: "user",
		name: "Individuais",
		color: colors.unrelated["009688"]
	},
	{
		type: "group",
		name: "Grupos",
		color: colors.palette.link
	}
]

const MESSAGES_FILTERS: MessagesFilter[] = [
	{
		id: "all",
		name: "Tudo"
	},
	{
		id: "unread",
		name: "Não lidas"
	}
]

const ChatListFilter: React.FC = () => {
	const chatGlobalStateStore = useChatGlobalStateStore()
	const classes = useStyles()
	const globalStateStore = useGlobalStateStore()
	const chatMethods = useChat()
	const customClasses = useCustomStyles()
	const userTeams = globalStateStore.user.user_teams.map(team => team.id)
	const instanceTeamsWithouUserTeams = globalStateStore.instance.teams.filter(team => !userTeams.includes(team.id))
	const [isFilterExpanded, setIsFilterExpanded] = useState<boolean>(false)
	const [loadingTagOptions, setLoadingTagOptions] = useState<boolean>(false)
	const [loadingTeamsOptions, setLoadingTeamsOptions] = useState<boolean>(false)
	const [loadingAttendantOptions, setLoadingAttendantOptions] = useState<boolean>(false)
	const inboxMessageGroupPermission = useSubscriptionLimits("permission", "inbox_message_group")
	const groupPermission = useSubscriptionLimits("permission", "group")

	const isAbleManageInboxGroupsPermission = !inboxMessageGroupPermission.mustBlock && !groupPermission.mustBlock

	const handleSearchChange = async (filter: Partial<IChatListFilter>) => {
		await chatGlobalStateStore.chatListPanel.chatListFilter.update(filter)
		await chatGlobalStateStore.chat.loadAllFromServer()
	}

	const handleClearFilters = () => {
		chatGlobalStateStore.chatListPanel.chatListFilter.resetFilters()
	}

	const attendantFilterOptions = chatGlobalStateStore.attendant.list.map(attendant => ({ id: attendant.id, name: attendant.name }))
	const tagFilterOptions = chatGlobalStateStore.tag.list
	const teamFilterOptions = chatGlobalStateStore.chatListPanel.chatListFilter.current.teams
	const actualChatTypesFilter = chatGlobalStateStore.chatListPanel.chatListFilter.current.chatTypes
		.filter(chatType => CHAT_TYPE_FILTERS.find(({ type }) => type === chatType))
		.map(chatType => CHAT_TYPE_FILTERS.find(({ type }) => type === chatType) as ChatTypeFilter)
	const actualAttendantFilter = chatGlobalStateStore.chatListPanel.chatListFilter.current.attendants
	const currentMessagesFilter = chatGlobalStateStore.chatListPanel.messagesFilter.current
	const actualTagsFilter = chatGlobalStateStore.chatListPanel.chatListFilter.current.tags
	const hasFilters = !chatGlobalStateStore.chatListPanel.chatListFilter.isDefaultFilter()
	const isCurrentUserAdmin = globalStateStore.instance?.user_in_instance_role?.code === "admin"
	const isCurrentAttendanceFilter = chatGlobalStateStore.chatListPanel.chatStatusFilter.current === "on-going" || chatGlobalStateStore.chatListPanel.chatStatusFilter.current === "queue"
	const isCurrentTeamFilter = chatGlobalStateStore.chatListPanel.chatStatusFilter.current === "queue"
	const showAttendantFilter = isCurrentAttendanceFilter && isCurrentUserAdmin
	const showTeamFilter = isCurrentTeamFilter && isCurrentUserAdmin
	const othersTab = TABS.find(tab => tab.slug === "others")
	const isOthersTab = othersTab?.availableChatStatus?.includes(chatGlobalStateStore.chatListPanel.chatStatusFilter.current)
	const newTab = TABS.find(tab => tab.slug === "new")
	const isNewTab = newTab?.availableChatStatus?.includes(chatGlobalStateStore.chatListPanel.chatStatusFilter.current)

	const currentFilteredChats = chatGlobalStateStore.chatListPanel.chatStatusFilter.current === "queue" ? (
		chatGlobalStateStore.chat.list.filter(chat => chatMethods.isQueueChat(chat))
	) : (
		chatGlobalStateStore.chat.list.filter(chat => chat.status === chatGlobalStateStore.chatListPanel.chatStatusFilter.current)
	)
	const currentFilteredChatsHash = currentFilteredChats.map(chat => chat.id).join("")

	const isWabaChannel = globalStateStore.instance.current_channel_type === "waba"

	/**
	 * `handleMessagesFilterChange` is a workaround function necessary for ensuring
	 * that only a single filter option remains selected in the `Autocomplete` component,
	 * even with its `multiple` property having the value `true`.
	 *
	 * Due to the requirement to use `renderTags` (to customize the display of tags
	 * and remove the default "X" close icon from each tag), `multiple` must be set to `true`.
	 * However, `multiple=true` allows multiple selections, which is not desired for this
	 * filter, as only one message filter should be active at any time.
	 *
	 * This function ensures that, whenever a new option is selected, it replaces any
	 * previously selected option, maintaining a single selection within the component.
	*/

	const handleMessagesFilterChange = async (newFilter: MessagesFilter[]) => {
		const oldFilter = chatGlobalStateStore.chatListPanel.messagesFilter.current

		const newMessagesFilterArray = newFilter.filter(filter => filter.id !== oldFilter.id)

		chatGlobalStateStore.chatListPanel.messagesFilter.change(newMessagesFilterArray[0])
	}

	const handleUpdateTagFilterOptions = async () => {
		setLoadingTagOptions(true)

		await promiseWithTimeout(chatGlobalStateStore.tag.loadAllFromServer, 1000)

		setLoadingTagOptions(false)
	}

	const handleUpdateInstanceTeamsWithouUserTeams = async () => {
		setLoadingTeamsOptions(true)

		const updateTeams = async () => {
			const data = await TeamService.getTeams()

			globalStateStore.setInstanceData({
				teams: data
			})
		}

		await promiseWithTimeout(updateTeams, 1000)

		setLoadingTeamsOptions(false)
	}

	const handleUpdateAttendantFilterOptions = async () => {
		setLoadingAttendantOptions(true)

		await promiseWithTimeout(chatGlobalStateStore.attendant.loadAllFromServer, 1000)

		setLoadingAttendantOptions(false)
	}

	const fromLastMessageDate = chatGlobalStateStore.chatListPanel.chatListFilter.current.fromLastMessageTransactedDate
	const toLastMessageDate = chatGlobalStateStore.chatListPanel.chatListFilter.current.toLastMessageTransactedDate
	const fromLastMessageDateOthersTab = chatGlobalStateStore.chatListPanel.chatListFilter.current.fromLastMessageTransactedDateOthersTab
	const toLastMessageDateOthersTab = chatGlobalStateStore.chatListPanel.chatListFilter.current.toLastMessageTransactedDateOthersTab
	const fromDateFilter = isOthersTab ? fromLastMessageDateOthersTab : fromLastMessageDate
	const toDateFilter = isOthersTab ? toLastMessageDateOthersTab : toLastMessageDate

	const displayUnreadMessagesFilter = !isNewTab

	return useCustomMemo(() => (
		<Accordion
			className={classes.container}
			expanded={isFilterExpanded}
		>
			{/* Must use style instead of class. With classes, seems like Material UI overrides the cursor style and it remains pointer. */}
			<AccordionSummary
				style={{
					cursor: "default"
				}}
			>
				<Grid
					container
					justify="space-between"
					alignItems="center"
					className={classes.box}
				>
					<Typography
						variant="caption"
						color="textPrimary"
					>
						{`${currentFilteredChats.length} ${currentFilteredChats.length === 1 ? "Conversa" : "Conversas"}`}
					</Typography>

					<Grid
						item
					>
						<Grid
							container
							alignItems="center"
						>
							{hasFilters &&
								<Tooltip
									title="Resetar filtros"
									className={customClasses.cursorPointer}
								>
									<ClearFilterIcon
										onClick={handleClearFilters}
										className={classes.grayIcon}
										fontSize="small"
									/>
								</Tooltip>
							}

							<Divider orientation="vertical" size={1} />

							<Typography
								variant="caption"
								color="textPrimary"
							>
								Filtros
							</Typography>

							<Divider orientation="vertical" size={1} />

							<FilterIcon
								className={customClasses.cursorPointer}
								onClick={() => { setIsFilterExpanded(!isFilterExpanded) }}
							/>
						</Grid>
					</Grid>
				</Grid>
			</AccordionSummary>

			<AccordionDetails>
				<Grid
					container
					justify="space-between"
					alignItems="center"
					className={`${classes.box}`}
					spacing={3}
				>
					{(
						<Grid
							item
							xs={12}
						>
							<Grid
								container
								direction="column"
							>
								<Typography
									variant="caption"
									color="textPrimary"
								>
									Última mensagem transacionada
								</Typography>

								<Divider orientation="horizontal" size={0.5} />

								<Grid
									container
									justify="space-between"
								>
									<MuiPickersUtilsProvider
										locale={ptBR}
										utils={DateFnsUtils}
									>
										<Grid
											className={classes.dateContainer}
										>
											<KeyboardDateTimePicker
												className={classes.filterInput}
												okLabel="Pronto"
												cancelLabel="Cancelar"
												ampm={false}
												value={fromDateFilter}
												inputVariant="outlined"
												onChange={(date) =>
													handleSearchChange({
														[isOthersTab
															? "fromLastMessageTransactedDateOthersTab"
															: "fromLastMessageTransactedDate"]: date as Date
													})
												}
												placeholder="De"
												size="small"
												format="dd/MM/yyyy HH:mm"
												InputAdornmentProps={{
													position: "start"
												}}
											/>
										</Grid>
									</MuiPickersUtilsProvider>

									<Divider orientation="vertical" size={1} />

									<MuiPickersUtilsProvider
										locale={ptBR}
										utils={DateFnsUtils}
									>
										<Grid
											className={classes.dateContainer}
										>
											<KeyboardDateTimePicker
												className={classes.filterInput}
												okLabel="Pronto"
												cancelLabel="Cancelar"
												ampm={false}
												value={toDateFilter}
												inputVariant="outlined"
												onChange={(date) =>
													handleSearchChange({
														[isOthersTab
															? "toLastMessageTransactedDateOthersTab"
															: "toLastMessageTransactedDate"]: date as Date
													})
												}
												placeholder="Até"
												size="small"
												format="dd/MM/yyyy HH:mm"
												InputAdornmentProps={{
													position: "start"
												}}
											/>
										</Grid>
									</MuiPickersUtilsProvider>
								</Grid>
							</Grid>
						</Grid>
					)}

					{isAbleManageInboxGroupsPermission && !isWabaChannel && (
						<Grid
							item
							xs={12}
						>
							<Grid
								container
								direction="column"
							>
								<Typography
									variant="caption"
									color="textPrimary"
								>
									Tipos de conversa
								</Typography>

								<Divider orientation="horizontal" size={0.5} />

								<Autocomplete
									multiple
									options={CHAT_TYPE_FILTERS}
									getOptionSelected={(option, value) => option.type === value.type}
									getOptionLabel={(option) => option.name}
									value={actualChatTypesFilter}
									fullWidth
									filterSelectedOptions
									onChange={(_, chatTypesFilter) => handleSearchChange({ chatTypes: chatTypesFilter.map(({ type }) => type) })}
									renderInput={(params) => (
										<TextField
											{...params}
											className={classes.filterInput}
											variant="outlined"
											placeholder="Tipos de conversa"
											{...({
												...params,
												InputProps: {
													...params.InputProps,
													startAdornment: [
														(
															<InputAdornment
																position="start"
																key="icon"
															>
																<ChatTypeFilterIcon />
															</InputAdornment>
														),
														params.InputProps.startAdornment
													]
												}
											})}
										/>
									)}
									renderTags={(value, getTagProps) => {
										return value.map((chatTypeFilter, index) => (
											<Tooltip
												key={index}
												title={chatTypeFilter.name}
											>
												<Chip
													label={chatTypeFilter.name}
													style={{
														color: colors.grayScale[11],
														backgroundColor: chatTypeFilter.color,
														maxWidth: deviceIsMobile() ? "239px" : "270px"
													}}
													size="small"
													{...getTagProps({ index })}
												/>
											</Tooltip>
										))
									}
									}
									size="small"
								/>
							</Grid>
						</Grid>
					)}

					<Grid
						item
						xs={12}
					>
						<Grid
							container
							direction="column"
						>
							<Typography
								variant="caption"
								color="textPrimary"
							>
								Tags
							</Typography>

							<Divider orientation="horizontal" size={0.5} />

							<Autocomplete
								multiple
								options={tagFilterOptions}
								getOptionSelected={(option, value) => option.id === value.id}
								getOptionLabel={(option) => option.name}
								value={actualTagsFilter}
								fullWidth
								filterSelectedOptions
								onChange={(_, tags) => handleSearchChange({ tags })}
								renderInput={(params) => (
									<TextField
										{...params}
										className={classes.filterInput}
										variant="outlined"
										placeholder="Tags"
										{...({
											...params,
											InputProps: {
												...params.InputProps,
												startAdornment: [
													(
														<InputAdornment
															position="start"
															key="icon"
														>
															<TagFilterIcon />
														</InputAdornment>
													),
													params.InputProps.startAdornment
												],
												endAdornment: (
													<>
														{ loadingTagOptions ? <CircularProgress color="inherit" size={20} /> : null }
														{params.InputProps.endAdornment}
													</>
												)
											}
										})}
									/>
								)}
								renderTags={(value, getTagProps) => {
									return value.map((tag, index) => (
										<Tooltip
											key={index}
											title={tag.name}
										>
											<Chip
												label={tag.name}
												style={{
													color: colors.grayScale[11],
													backgroundColor: convertRGBAObjectToString(tag.color),
													maxWidth: deviceIsMobile() ? "239px" : "270px"
												}}
												size="small"
												{...getTagProps({ index })}
											/>
										</Tooltip>
									))
								}}
								size="small"
								onOpen={handleUpdateTagFilterOptions}
								loading={loadingTagOptions}
							/>
						</Grid>
					</Grid>

					{
						showTeamFilter && (
							(
								<Grid
									item
									xs={12}
								>
									<Grid
										container
										direction="column"
									>
										<Typography
											variant="caption"
											color="textPrimary"
										>
											Equipes
										</Typography>

										<Divider orientation="horizontal" size={0.5} />

										<Autocomplete
											multiple
											options={instanceTeamsWithouUserTeams}
											getOptionSelected={(option, value) => option.id === value.id}
											getOptionLabel={(option) => option.name}
											value={teamFilterOptions}
											fullWidth
											filterSelectedOptions
											onChange={(_, teams) => handleSearchChange({ teams })}
											renderInput={(params) => {
												return (
													<TextField
														className={classes.filterInput}
														variant="outlined"
														placeholder="Equipes"
														{...({
															...params,
															InputProps: {
																...params.InputProps,
																startAdornment: [
																	(
																		<InputAdornment
																			position="start"
																			key="icon"
																		>
																			<AttendantFilterIcon />
																		</InputAdornment>
																	),
																	params.InputProps.startAdornment
																],
																endAdornment: (
																	<>
																		{ loadingTeamsOptions ? <CircularProgress color="inherit" size={20} /> : null }
																		{params.InputProps.endAdornment}
																	</>
																)
															}
														})}
													/>
												)
											}}
											renderTags={(value, getTagProps) => {
												return value.map((team, index) => (
													<Tooltip
														title={team.name}
														key={index}
													>
														<Chip
															label={team.name}
															size="small"
															style={{
																maxWidth: deviceIsMobile() ? "239px" : "270px"
															}}
															{...getTagProps({ index })}
														/>
													</Tooltip>
												))
											}
											}
											size="small"
											onOpen={handleUpdateInstanceTeamsWithouUserTeams}
											loading={loadingTeamsOptions}
										/>
									</Grid>
								</Grid>)
						)
					}

					{showAttendantFilter && (
						<Grid
							item
							xs={12}
						>
							<Grid
								container
								direction="column"
							>
								<Typography
									variant="caption"
									color="textPrimary"
								>
									Atendentes
								</Typography>

								<Divider orientation="horizontal" size={0.5} />

								<Autocomplete
									multiple
									options={attendantFilterOptions}
									getOptionSelected={(option, value) => option.id === value.id}
									getOptionLabel={(option) => option.name}
									value={actualAttendantFilter}
									filterSelectedOptions
									onChange={(_, attendants) => handleSearchChange({ attendants })}
									renderInput={(params) => {
										return (
											<TextField
												className={classes.filterInput}
												variant="outlined"
												placeholder="Atendentes"
												{...({
													...params,
													InputProps: {
														...params.InputProps,
														startAdornment: [
															(
																<InputAdornment
																	position="start"
																	key="icon"
																>
																	<AttendantFilterIcon />
																</InputAdornment>
															),
															params.InputProps.startAdornment
														],
														endAdornment: (
															<>
																{ loadingAttendantOptions ? <CircularProgress color="inherit" size={20} /> : null }
																{params.InputProps.endAdornment}
															</>
														)
													}
												})}
											/>
										)
									}}
									renderTags={(value, getTagProps) => {
										return value.map((attendant, index) => (
											<Tooltip
												key={index}
												title={attendant.name}
											>
												<Chip
													label={attendant.name}
													size="small"
													style={{
														maxWidth: deviceIsMobile() ? "239px" : "270px"
													}}
													{...getTagProps({ index })}
												/>
											</Tooltip>
										))
									}
									}
									size="small"
									onOpen={handleUpdateAttendantFilterOptions}
									loading={loadingAttendantOptions}
								/>
							</Grid>
						</Grid>
					)}

					{displayUnreadMessagesFilter && (
						<Grid
							item
							xs={12}
						>
							<Grid
								container
								direction="column"
							>
								<Typography
									variant="caption"
									color="textPrimary"
								>
									Mensagens
								</Typography>

								<Divider orientation="horizontal" size={0.5} />

								<Autocomplete
									size="small"
									multiple={true}
									value={[currentMessagesFilter]}
									options={MESSAGES_FILTERS}
									filterSelectedOptions
									disableClearable={true}
									getOptionSelected={(option, value) => option.id === value.id}
									getOptionLabel={(option) => option.name}
									renderInput={(params) => {
										return (
											<TextField
												className={classes.filterInput}
												variant="outlined"
												placeholder="Mensagens"
												{...({
													...params,
													InputProps: {
														...params.InputProps,
														startAdornment: [
															(
																<InputAdornment
																	position="start"
																	key="icon"
																>
																	<MessageFilterIcon />
																</InputAdornment>
															),
															params.InputProps.startAdornment
														],
														endAdornment: (
															<>
																{params.InputProps.endAdornment}
															</>
														)
													}
												})}
											/>
										)
									}}
									renderTags={(value) => {
										return value.map((messageFilter) => (
											<Tooltip
												key={messageFilter.id}
												title={messageFilter.name}
											>
												<Chip
													label={messageFilter.name}
													size="small"
													style={{
														maxWidth: deviceIsMobile() ? "239px" : "270px"
													}}
												/>
											</Tooltip>
										))
									}
									}
									onChange={(_, newFilter) => handleMessagesFilterChange(newFilter)}
								/>
							</Grid>
						</Grid>
					)}
				</Grid>
			</AccordionDetails>
		</Accordion>
	), [
		isFilterExpanded,
		chatGlobalStateStore.chatListPanel.chatListFilter.currentHash,
		chatGlobalStateStore.chatListPanel.chatStatusFilter.currentHash,
		chatGlobalStateStore.attendant.listHash,
		chatGlobalStateStore.tag.listHash,
		chatGlobalStateStore.chat.list.length,
		showAttendantFilter,
		currentFilteredChatsHash,
		tagFilterOptions
	])
}

export default ChatListFilter
