import { makeStyles } from "@material-ui/core"
import newColors from "@/styles/newColors"

const useStyles = makeStyles(theme => ({
	button: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: newColors.blue[600],
		height: theme.spacing(2.75),
		borderRadius: theme.spacing(0.5),
		minWidth: 34,
		padding: theme.spacing(1, 1.5),
		fontSize: theme.spacing(1.5),
		"&:hover": {
			backgroundColor: newColors.blue[600],
			opacity: 0.8,
			cursor: "pointer"
		}
	},
	buttonText: {
		fontFamily: "IBM Plex Sans",
		fontStyle: "normal",
		fontSize: theme.spacing(1.75),
		color: newColors.grey[950],
		fontWeight: 500,
		paddingLeft: theme.spacing(0.5),
		height: "100%",
		paddingBottom: theme.spacing(2.75)
	},
	tabs: {
		position: "relative",
		"&::before": {
			content: "close-quote",
			position: "absolute",
			width: "100%",
			height: "1px",
			bottom: 0,
			left: 0,
			backgroundColor: newColors.grey[200]
		}
	},
	tabIndicator: {
		backgroundColor: newColors.purple[600]
	},
	tab: {
		color: `${newColors.grey[400]} !important`,
		opacity: 1,
		fontSize: 14
	}
}))

export default useStyles
