import React, { useEffect, useState } from "react"

import { Chip, Grid, InputAdornment, TextField, Tooltip, Typography } from "@material-ui/core"
import { ActionDialog, Divider, Notification } from "@/components"

import { ActiveCampaignContactPickedData } from "@/store/ActiveCampaignExternalChatGlobalState"
import { Autocomplete } from "@material-ui/lab"
import { Contacts as ContactsIcon } from "@material-ui/icons"
import colors from "@/styles/colors"
import useStyles from "@/pages/ActiveCampaignChatQuickViewPage/PhoneNumberSelector/styles"
import { deviceIsMobile } from "@/utils/checkDevice"

type PhoneNumberSelectorDialogProps = {
	onSave?: () => Promise<void> | void
	onClose?: () => void
	openDialog?: boolean
	getAllContacts: () => ActiveCampaignContactPickedData[]
	defaultSelectedContact: ActiveCampaignContactPickedData | null
	setSelectedContact: (selectedContact: ActiveCampaignContactPickedData) => void
}

const PhoneNumberSelectorDialog: React.FC<PhoneNumberSelectorDialogProps> = (props: PhoneNumberSelectorDialogProps) => {
	const {
		getAllContacts,
		setSelectedContact,
		onClose,
		onSave,
		defaultSelectedContact
	} = props

	const classes = useStyles()

	const allContacts = getAllContacts()

	const [internalSelectedContact, setInternalSelectedContact] = useState<ActiveCampaignContactPickedData | null>(defaultSelectedContact)

	const handleSelectPhoneNumber = (contact: ActiveCampaignContactPickedData) => {
		setSelectedContact(contact)
	}

	useEffect(() => {
		setInternalSelectedContact(defaultSelectedContact)
	}, [defaultSelectedContact])

	return (
		<ActionDialog
			title={"Selecione um contato para carregar"}
			onSave={() => {
				if (internalSelectedContact) {
					const hasChangedContactSelection = internalSelectedContact.phone !== defaultSelectedContact?.phone
					if (hasChangedContactSelection) {
						handleSelectPhoneNumber(internalSelectedContact)
						onSave?.()
					} else {
						onClose?.()
					}
				} else {
					Notification.error({
						message: "Você precisa selecionar um contato para carregar um chat"
					})
				}
			}}
			saveText={"Carregar chat"}
			onClose={() => {
				onClose?.()
			}}
			openDialog={props.openDialog}
			loading={false}
			fullWidth
		>
			<Grid container>
				<Grid item xs={12}>
					<Typography
						variant="caption"
						color="textPrimary"
					>
						Selecione um contato
					</Typography>

					<Divider orientation="horizontal" size={0.5} />

					<Autocomplete
						multiple={false}
						options={allContacts}
						getOptionSelected={(option, value) => option.id === value.id}
						getOptionLabel={(option) => {
							const phoneNumber = option.phone || "Sem telefone"
							const email = option.email || "Sem email"

							const isDefaultNullOption = option.id === ""
							const optionLabel = isDefaultNullOption ? "" : `${phoneNumber} - ${email}`

							return optionLabel
						}}
						defaultValue={null}
						value={internalSelectedContact}
						fullWidth
						filterSelectedOptions
						onChange={(_, contact) => {
							setInternalSelectedContact(contact || null)
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								className={classes.filterInput}
								variant="outlined"
								placeholder="Selecione um contato"
								{...({
									...params,
									InputProps: {
										...params.InputProps,
										startAdornment: [
											(
												<InputAdornment
													position="start"
													key="icon"
												>
													<ContactsIcon />
												</InputAdornment>
											),
											params.InputProps.startAdornment
										]
									}
								})}
							/>
						)}
						renderTags={(value, getTagProps) => {
							return value.map((chatTypeFilter, index) => (
								<Tooltip
									key={index}
									title={chatTypeFilter.email}
								>
									<Chip
										label={chatTypeFilter.email}
										style={{
											color: colors.grayScale[11],
											maxWidth: deviceIsMobile() ? "239px" : "270px"
										}}
										size="small"
										{...getTagProps({ index })}
									/>
								</Tooltip>
							))
						}
						}
						size="small"
					/>
				</Grid>
			</Grid>
		</ActionDialog>
	)
}

export default PhoneNumberSelectorDialog
