import React from "react"
import { Grid } from "@material-ui/core"
import { Loading } from "@/components"

import { useChatGlobalStateStore } from "@/store/ChatGlobalState"
import { fullDatetime } from "@/utils/time"
import { Snooze } from "@/protocols/snooze"

import MessageItem from "@/components/ChatMessageBuilder/MessageItem"
import ScheduledSnoozeContentSkeleton from "@/pages/Attendance/Chat/ConversationPanel/ConversationHeader/Snooze/ScheduledSnoozeContent/skeletons/ScheduledSnoozeContentSkeleton"

type ScheduledSnoozeContentProps = {
	snooze: Snooze
	loading: boolean
}

const ScheduledSnoozeContent: React.FC<ScheduledSnoozeContentProps> = (props) => {
	const { loading, snooze } = props

	const chatGlobalStateStore = useChatGlobalStateStore()
	const scheduleTime = chatGlobalStateStore.chat.current?.schedule?.time

	return (
		<Loading loading={loading} customLoadingElement={<ScheduledSnoozeContentSkeleton />}>
			<Grid item xs={12}>
				{fullDatetime(new Date(scheduleTime as Date))}
			</Grid>
			{snooze?.content && (
				<Grid item xs={12}>
					<MessageItem
						type={snooze?.content?.messages[0]?.type}
						content={snooze?.content?.messages[0]?.content}
						mediaName={snooze?.content?.messages[0]?.mediaName}
					/>
				</Grid>
			)}
		</Loading>
	)
}

export default ScheduledSnoozeContent
