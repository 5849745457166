import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid } from "@material-ui/core"

const CannyFeedbackSkeleton = () => {
	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<Grid container spacing={2}>
					<Grid item xs={5}>
						<Skeleton variant="text" height={30} />
					</Grid>

					<Grid item xs={12}>
						<Skeleton variant="rect" style={{ borderRadius: 4 }} height={210} />
					</Grid>
					<Grid item xs={12}>
						<Skeleton variant="rect" style={{ borderRadius: 4 }} height={180} />
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default CannyFeedbackSkeleton
