import React from "react"

import { Divider } from "@/components"

import Metrics from "@/pages/Admin/Dashboard/Metrics"

import WhatsappConnectionFlow from "@/@integrations/Whatsapp/components/WhatsappConnectionFlow"
import WABAConnectionFlow from "@/@integrations/WABA/components/WABAConnectionFlow"
import { useGlobalStateStore } from "@/store/GlobalState"

import { Grid, Link as LinkMUI, Typography } from "@material-ui/core"
import { WarningTwoTone as WarningIcon } from "@material-ui/icons"
import { isAbleToValidateEmail } from "@/utils/time"
import { IChannel } from "@/protocols/channel"
import { letalkLinks } from "@/utils/link"

import AlertContainer from "@/components/AlertContainer"
import DateService from "@/services/Date"
import * as dateFns from "date-fns"

import newColors from "@/styles/newColors"

const LIMIT_OF_DAYS_WITHOUT_CONNECTION = 3

const Dashboard: React.FC = () => {
	const globalStateStore = useGlobalStateStore()

	const canValidateEmail = isAbleToValidateEmail()
	const currentChannel = globalStateStore.currentChannel as IChannel

	const showAlertForDaysWithoutConnection = React.useMemo(() => {
		if (currentChannel?.syncControlData) {
			const currentDate = DateService.currentWorldDate()
			const lastConnection = new Date(currentChannel.syncControlData.last_authentication_successful_date)

			const differenceInDays = dateFns.differenceInDays(currentDate, lastConnection)

			return differenceInDays > LIMIT_OF_DAYS_WITHOUT_CONNECTION
		}
		return false
	}, [currentChannel])

	return (
		<>
			<Grid container spacing={2}>
				{canValidateEmail && (
					<Grid item xs={12}>
						<AlertContainer
							title="ATENÇÃO"
							alertType="warning"
							icon={<WarningIcon htmlColor={newColors.yellow[500]} fontSize="large" />}
						>
							<Typography variant="body2">
						Seu e-mail não está verificado. Ajude a proteger sua conta contra o acesso não autorizado.{" "}
								<LinkMUI
									href={"/admin/profile"}
									target="_self"
									underline="always"
									style={{ textDecoration: "none" }}
								>
							Validar email
								</LinkMUI>
							</Typography>
						</AlertContainer>
					</Grid>
				)}

				{showAlertForDaysWithoutConnection && (
					<Grid item xs={12}>
						<AlertContainer
							title="Você está há mais de 3 dias sem fazer uma nova conexão."
							alertType="warning"
							icon={<WarningIcon htmlColor={newColors.yellow[500]} fontSize="large" />}
						>
							<Typography variant="body2">
						Ficar muitos dias sem fazer uma nova conexão pode trazer alguns problemas, como: falha no envio e no recebimento de mensagens, mensagens que não foram sincronizadas, mensagens duplicadas, entre outros.{" "}
								<LinkMUI
									href={letalkLinks.wikiHowToCreateANewSession}
									target="_blank"
									underline="none"
								>
							Veja como é simples prevenir
								</LinkMUI>
							</Typography>
						</AlertContainer>
					</Grid>
				)}
			</Grid>

			<Divider orientation="horizontal" size={2} />

			{globalStateStore.currentChannel?.channelType === "whatsapp" && (
				<WhatsappConnectionFlow />
			)}

			{globalStateStore.currentChannel?.channelType === "waba" && (
				<WABAConnectionFlow />
			)}

			<Divider orientation="horizontal" size={8} />

			<Metrics />
		</>
	)
}

export default Dashboard
