import React, { createContext, useContext, useState, useRef } from "react"
import _ from "lodash"

import useAsyncState from "@/hooks/useAsyncState"
import useSocket from "@/hooks/useSocket"
import { ErrorType } from "@/hooks/useValidation"

import { sortMessagesInAscendingOrder, getLastChatMessage } from "@/utils/chat"
import { runAfterReactRender } from "@/utils/node"
import { getTotalPages } from "@/utils/table"

import ErrorHandlerService from "@/services/ErrorHandler"
import { Media } from "@/services/Media"

import { IChat, IMessage, Attendance, IChatType, AssignAttendanceData } from "@/protocols/channel"
import { DeepPartial } from "@/protocols/utility"
import { Tag } from "@/protocols/tag"

import { TextInputHandler } from "@/pages/Attendance/Chat/ConversationPanel/Input/TextInput"

import { Notification } from "@/components"
import { ScrolledBottomListHandler } from "@/components/ScrolledBottomList"

import MediaValidation from "@/validations/MediaValidation"
import useActiveCampaignChatQuickView from "@/hooks/useActiveCampaignChatQuickView"
import { UserChatSettingsProps } from "@/protocols/settings"
import { Chat, Message } from "@/protocols/chatGlobalStateProtocol"
import { ActiveCampaignContactWindowTyping } from "zoid"

export type ChatEarlierMessagesLoadCursor = {
	page: number
	lastLoads: Record<number, Message[]>
}

type ProcessingStepsDialog = {
	opened: boolean
	messageId: string | null
}

export type ChatListFilter = {
	attendants: Array<{ id: number, name: string }>
	tags: Tag[]
	text: string
	chatTypes: IChatType[]
	fromLastMessageTransactedDate: Date
	toLastMessageTransactedDate: Date
	teams: Array<{
		id: number
		name: string
	}>
}

export type QueueChatList = {
	[key: string]: {
		exhibitionName: string
		data: Chat[]
	}
}

export type QueueChatInterfaceList = {
	type: "title" | "chat"
	// eslint-disable-next-line
	data: any | Chat
	height: number
}

export type ChatLoadingError = "ChatNotFound" | "ClientNotFound" | "GenericError" | "ActiveCampaignApiPluginDisabled" | null

type RepresentantUserInfo = {
	userId: number
	userName: string
	authToken: string
	instanceId: number
	inboxChannelUserChatSettings: UserChatSettingsProps
}

export type ActiveCampaignContactPickedData = Pick<ActiveCampaignContactWindowTyping, "id" | "email" | "phone"> & { fullName: string }

type ActiveCampaignExternalChatStateContextData = {
	message: {
		current: Message[]
		currentHash: string
		list: Message[]
		listAll: () => Message[]
		getById: (messageId: string) => Message
		setup: (messages: IMessage[]) => Promise<void>
		updateById: (messageId: string, data: Partial<Message>) => Promise<void>
		removeById: (messageId: string[]) => Promise<void>
		add: (message: Message) => Promise<void>
		loadEarlierMessages(): Promise<void>
		setRead(): Promise<void>
		processingStepsDialog: {
			open: (messageId: string) => void
			close: () => void
			current: Message | null
			messageId: string | null
			isOpened: boolean
		}
	}
	chat: {
		exists: (chatId: number) => boolean
		current: Chat | undefined
		setup: (chat: IChat) => Promise<void>
		update: (data: DeepPartial<Chat>) => Promise<void>
		loadFromServer: () => Promise<void>
		loadingFromServer: boolean
		earlierMessages: {
			setCursor: (data: Partial<ChatEarlierMessagesLoadCursor>) => Promise<void>
			resetCursor: () => Promise<void>
			getCachedMessages: (page: number) => Message[] | null
			loadedInitialMessages: () => boolean
		}
		chatLoadingError: ChatLoadingError
		setChatLoadingError: (chatLoadingError: Partial<ChatLoadingError>) => ChatLoadingError
	}
	attendance: {
		current: Attendance | undefined
		take: () => Promise<void>
		assign: (assign: AssignAttendanceData) => Promise<void>
		finish: () => Promise<void>
	}
	conversationPanel: {
		textInput: {
			ref: React.RefObject<TextInputHandler>
			focus: () => void
			value: string
			clear: () => void
			addText: (text: string) => void
		}
		scrolledBottomList: {
			ref: React.RefObject<ScrolledBottomListHandler>
			enableAutoScrollBottom: () => void
			disableAutoScrollBottom: () => void
			lockScrollTop: () => void
			unlockScrollTop: () => void
			scrollPastBeginning: {
				isPast: boolean,
				change: (totalScrollHeight: number, currentScrollHeight: number) => void
			}
		},
		media: {
			current: Media[]
			clear: () => void
			add: (medias: Media[]) => void
		},
		replyMessage: {
			current: Message | undefined
			clear: () => void
			change: (messageId: string) => void
		}
	}
	representantUser: {
		current: RepresentantUserInfo | undefined
		setup: (representantUserInfo: RepresentantUserInfo) => Promise<void>
	}
	activeCampaignContacts: {
		getAll: () => ActiveCampaignContactPickedData[]
		set: (contacts: ActiveCampaignContactPickedData[]) => void
		currentSelected: ActiveCampaignContactPickedData | null
		setSelected: (selectedContact: ActiveCampaignContactPickedData) => Promise<void>
	}
}

export const EARLIER_MESSAGES_DEFAULT_PAGE = 0

export const EARLIER_MESSAGES_DEFAULT_ROWS_PER_PAGE = 20

const ActiveCampaignExternalChatGlobalState = createContext<ActiveCampaignExternalChatStateContextData>({} as ActiveCampaignExternalChatStateContextData)

export const useActiveCampaignExternalChatGlobalStateStore = () => useContext(ActiveCampaignExternalChatGlobalState)

const ActiveCampaignExternalChatGlobalStateProvider: React.FC = (props) => {
	const { children } = props

	const socket = useSocket()
	const activeCampaignChatQuickView = useActiveCampaignChatQuickView()

	const [scrollPastBeginning, setScrollPastBeginning] = useState<boolean>(false)

	const [processingStepsDialog, setProcessingStepsDialog] = useAsyncState({} as ProcessingStepsDialog)

	const [representantUserInfo, setRepresentantUserInfo] = useAsyncState<RepresentantUserInfo>({} as RepresentantUserInfo)

	const [chat, setChat] = useAsyncState<Chat>({} as Chat)
	const [chatLoadingError, setChatLoadingError] = useAsyncState<ChatLoadingError>(null)
	const [loadingChatsFromServer, setLoadingChatsFromServer] = useState(true)

	const [chatEarlierMessagesLoadCursor, setChatEarlierMessagesLoadCursor] = useAsyncState<ChatEarlierMessagesLoadCursor>({ page: EARLIER_MESSAGES_DEFAULT_PAGE, lastLoads: {} })
	const [messages, setMessages] = useAsyncState({} as Record<string, Message>)

	const [medias, setMedias] = useState<Media[]>([])
	const [replyMessageId, setReplyMessageId] = useState<string>("")

	const textInputRef = useRef<TextInputHandler>(null)
	const scrolledBottomListRef = useRef<ScrolledBottomListHandler>(null)

	const [allActiveCampaignContacts, setAllActiveCampaignContacts] = useState<ActiveCampaignContactPickedData[]>([])
	const [selectedActiveCampaignContact, setSelectedActiveCampaignContact] = useAsyncState<ActiveCampaignContactPickedData | null>(null)

	const chatState: ActiveCampaignExternalChatStateContextData = {
		representantUser: {
			get current (): RepresentantUserInfo | undefined {
				return representantUserInfo.current
			},
			async setup (representantUserInfo: RepresentantUserInfo): Promise<void> {
				await setRepresentantUserInfo(lastRepresentantUserInfo => {
					const updatedRepresentantUserInfo = { ...lastRepresentantUserInfo, ...representantUserInfo }

					return updatedRepresentantUserInfo
				})
			}
		},
		attendance: {
			get current (): Attendance | undefined {
				const currentAttendance = chatState.chat.current?.attendance

				return currentAttendance
			},
			async take (): Promise<void> {
				if (!chat) {
					return
				}

				const oldChatStatus = chat.current.status
				const { id, inboxChannelId, channelType } = chat.current

				await chatState.chat.update({
					status: "on-going",
					attendance: {
						assignUserId: null,
						assignTeamId: null,
						assignmentQueueType: null,
						userId: representantUserInfo.current.userId,
						userName: representantUserInfo.current.userName,
						createdAt: String(new Date()),
						status: "active"
					}
				})

				chatState.conversationPanel.textInput.focus()
				chatState.message.setRead()

				await socket.takeAttendance({
					channelType,
					inboxChannelId,
					inboxChannelChatId: id
				}).then((success) => {
					if (!success) {
						Notification.error({ message: "Houve um erro ao assumir o atendimento, tente novamente." })
						chatState.chat.update({
							status: oldChatStatus,
							attendance: {
								userId: null,
								userName: ""
							}
						})
					}
				})
			},
			async assign (assign): Promise<void> {
				const actualAttendance = chatState.attendance.current

				/**
				 * Rollback must be a fixed variable
				 * If the state is referenced directly, state values will change
				 * and old values wont exist when doing rollback update
				*/
				const rollback: Chat = {
					...chat.current,
					attendance: {
						...actualAttendance
					}
				}

				if (!chat.current) {
					return
				}

				const { id, inboxChannelId, channelType } = chat.current

				socket.assignAttendance({
					channelType,
					inboxChannelId,
					inboxChannelChatId: id,
					assignedUserId: assign.assignedUserId,
					assignedTeamId: assign.assignedTeamId,
					assignmentQueueType: assign.assignmentQueueType,
					assignmentObservation: assign.assignmentObservation,
					wasStartedBy: "assign"
				}).then((success) => {
					if (!success) {
						Notification.error({ message: "Houve um erro ao transferir o atendimento, tente novamente." })
						chatState.chat.update(rollback)
					}
				})

				await chatState.chat.update({
					status: "queue",
					attendance: {
						status: "waiting",
						userName: representantUserInfo.current.userName,
						createdAt: String(new Date())
					}
				})
			},
			async finish (): Promise<void> {
				if (!chat.current) {
					return
				}

				const { id, inboxChannelId, channelType } = chat.current
				const { userName, userId } = chat.current.attendance

				await chatState.chat.update({
					status: "archived",
					attendance: {
						userName: "",
						userId: null,
						assignTeamId: null,
						assignUserId: null,
						assignmentQueueType: null,
						status: "finished"
					}
				})

				await socket.finishAttendance({
					channelType,
					inboxChannelId,
					inboxChannelChatId: id
				}).then((success) => {
					if (!success) {
						Notification.error({ message: "Houve um erro ao finalizar o atendimento, tente novamente." })
						chatState.chat.update({
							status: chat.current.status,
							attendance: {
								userId: userId,
								userName: userName,
								status: "active"
							}
						})
					}
				})
			}
		},
		message: {
			get currentHash (): string {
				const hash = chatState.message.current.map(message => message.id).join("")

				return hash
			},
			async setRead (): Promise<void> {
				if (!chat.current) {
					return
				}
				/**
				 * The user only can set messages read on a chat
				 * it has an on-going attendance, since the attendance queue
				 * is not owned by any customer.
				 */
				const isQueueChat = chat.current.status === "queue"
				const chatHasUnreadMessages = Boolean(chat.current.unreadMessagesCount)

				if (chatHasUnreadMessages && !isQueueChat) {
					socket.setReadMessages({
						channelType: chat.current.channelType,
						inboxChannelId: chat.current.inboxChannelId,
						inboxChannelChatId: chat.current.id
					})

					await chatState.chat.update({
						unreadMessagesCount: 0
					})
				}
			},
			get list (): Message[] {
				const messageList = Object.values(messages.current).sort(sortMessagesInAscendingOrder)

				return messageList
			},
			listAll (): Message[] {
				const chatMessages = Object.values(messages.current)
					.filter(message => message.inboxChannelChatId === chat.current.id)
					.sort(sortMessagesInAscendingOrder)

				return chatMessages
			},
			async removeById (messageIds: string[]): Promise<void> {
				setMessages(lastState => {
					const updatedState = _.omit(lastState, messageIds)

					return updatedState
				})
			},
			async loadEarlierMessages (): Promise<void> {
				try {
					const chatExists = Boolean(chat)
					const alreadyLoadedAllEarlierMessages = Boolean(chat.current?.fullyLoadedAllEarlierMessages)
					const alreadyLoadingEarlierMessages = Boolean(chat.current?.loadingEarlierMessages)

					const canLoadEarlierMessages = chatExists && !alreadyLoadedAllEarlierMessages && !alreadyLoadingEarlierMessages

					if (!canLoadEarlierMessages) {
						return
					}

					chatState.chat.update({ loadingEarlierMessages: true })

					const cursor = chatEarlierMessagesLoadCursor.current || { page: EARLIER_MESSAGES_DEFAULT_PAGE, lastLoads: {} }

					const clientChatMessages = await activeCampaignChatQuickView.getClientChatMessages({
						inboxChannelChatId: chat.current.id,
						inboxChannelId: chat.current.inboxChannelId,
						rowsPerPage: EARLIER_MESSAGES_DEFAULT_ROWS_PER_PAGE,
						page: cursor.page
					})

					const totalPages = getTotalPages(clientChatMessages?.count, EARLIER_MESSAGES_DEFAULT_ROWS_PER_PAGE)
					const nextPage = cursor.page + 1
					const fullyLoadedAllEarlierMessages = nextPage >= totalPages

					chatState.chat.update({
						fullyLoadedAllEarlierMessages,
						loadingEarlierMessages: false
					})

					chatState.conversationPanel.scrolledBottomList.lockScrollTop()

					chatState.message.setup(clientChatMessages?.messages).then(() => {
						chatState.conversationPanel.scrolledBottomList.unlockScrollTop()
					})

					chatState.chat.earlierMessages.setCursor({
						page: nextPage,
						lastLoads: {
							[cursor.page]: clientChatMessages?.messages
						}
					})
				} catch (error) {
					ErrorHandlerService.handle(error as ErrorType)
				}
			},
			get current (): Message[] {
				const currentMessages = Object.values(messages.current)
					.sort(sortMessagesInAscendingOrder)

				return currentMessages
			},
			getById (messageId: string): Message {
				const message = messages.current[messageId]

				return message
			},
			async setup (messages: IMessage[]): Promise<void> {
				await setMessages(lastState => {
					const updatedMessages = { ...lastState }

					messages.forEach(message => {
						updatedMessages[message.id] = {
							...message,
							uploadingMedia: false
						}
					})

					setChat(lastState => {
						const updatedChats = { ...lastState }

						if (updatedChats) {
							updatedChats.lastMessage = getLastChatMessage(chat.current.id, updatedMessages)
							updatedChats.syncingMessages = false
						}

						return updatedChats
					})

					return updatedMessages
				})
			},
			async updateById (messageId: string, data: Partial<Message>): Promise<void> {
				await setMessages(lastState => {
					const updatedMessages = { ...lastState }

					if (!updatedMessages[messageId]) {
						return lastState
					}

					updatedMessages[messageId] = _.merge(updatedMessages[messageId], data)

					return updatedMessages
				})
			},
			async add (message: Message): Promise<void> {
				await setMessages(lastState => {
					const updatedState = { ...lastState }
					updatedState[message.id] = message

					setChat(lastState => {
						const updatedChats = { ...lastState }

						if (updatedChats) {
							updatedChats.lastMessage = getLastChatMessage(message.inboxChannelChatId, updatedState)
						}

						return updatedChats
					})

					return updatedState
				})
			},
			processingStepsDialog: {
				open (messageId: string): void {
					setProcessingStepsDialog(() => ({ opened: true, messageId }))
				},
				close (): void {
					setProcessingStepsDialog(lastState => ({ ...lastState, opened: false }))
				},
				get current (): Message | null {
					if (processingStepsDialog.current.messageId) {
						return chatState.message.getById(processingStepsDialog.current.messageId)
					}

					return null
				},
				get isOpened (): boolean {
					return Boolean(processingStepsDialog.current.opened)
				},
				get messageId (): string | null {
					return processingStepsDialog.current.messageId
				}
			}
		},
		chat: {
			exists (): boolean {
				const exists = Boolean(chat.current.id)
				return exists
			},
			get current (): Chat | undefined {
				return chat.current
			},
			async setup (newChat: IChat): Promise<void> {
				/**
				 * When this method is called, a new chat is rendered
				 * Cannot call update method since lastState need to be removed
				 */
				await setChat(lastState => {
					if (!newChat) {
						return lastState
					}

					return {
						...newChat,
						fullyLoadedAllEarlierMessages: false,
						loadingEarlierMessages: false,
						finishedSyncingMessages: false,
						syncingMessages: true,
						unreadMessagesCount: 0,
						lastMessage: undefined
					}
				})

				setMessages(() => {
					return {}
				})

				await chatState.message.loadEarlierMessages()
			},
			async update (data: DeepPartial<Chat>): Promise<void> {
				await setChat(lastState => {
					if (!data) {
						return lastState
					}

					const updatedChat = _.merge(_.clone(lastState), data)

					return updatedChat
				})
			},
			async loadFromServer (): Promise<void> {
				try {
					setLoadingChatsFromServer(true)

					if (chatState.chat.current) {
						await chatState.chat.earlierMessages.setCursor({
							lastLoads: {},
							page: 0
						})
					}

					const selectedContact = chatState.activeCampaignContacts.currentSelected
					const clientChat = await activeCampaignChatQuickView.getClientChatByPhoneNumber(selectedContact?.phone || "")

					const newChat = clientChat.inboxChannelChat

					chatState.chat.setChatLoadingError(clientChat.chatLoadingError)
					if (newChat) {
						await chatState.chat.setup(newChat)
					}

					setLoadingChatsFromServer(false)
				} catch (error) {
					if ((error as ErrorType)?.response?.data?.codeMessages?.text === "number_of_characters_less_than_four") {
						Notification.warning({
							message: "Você deve inserir mais de 4 caracteres."
						})
					}

					ErrorHandlerService.handle(error as ErrorType)
					setLoadingChatsFromServer(false)
				}
			},
			get loadingFromServer (): boolean {
				return loadingChatsFromServer
			},
			earlierMessages: {
				async setCursor (data: Partial<ChatEarlierMessagesLoadCursor>): Promise<void> {
					await setChatEarlierMessagesLoadCursor(lastState => {
						let updatedCursors = { ...lastState }

						if (!updatedCursors) {
							updatedCursors = {
								page: EARLIER_MESSAGES_DEFAULT_PAGE,
								lastLoads: {}
							}
						}

						updatedCursors = _.merge(updatedCursors, data)

						return updatedCursors
					})
				},
				/**
				 * This method return page = 1, be careful using it
				 * To reset cursor properly, use setCursor
				 */
				async resetCursor (): Promise<void> {
					await setChatEarlierMessagesLoadCursor(lastState => {
						let updatedCursors = { ...lastState }

						const oldCursor = updatedCursors

						if (oldCursor) {
							const pageToKeepCached = 0

							const firstPageLoaded = oldCursor?.lastLoads?.[pageToKeepCached]

							const firstPageWasAlreadyLoaded = Boolean(firstPageLoaded)

							if (firstPageWasAlreadyLoaded) {
								const lastLoadedMessage = firstPageLoaded[firstPageLoaded.length - 1]

								if (lastLoadedMessage) {
									const chatMessages = chatState.message.listAll()

									const messageIdsToRemove = chatMessages
										.filter(chatMessage => new Date(chatMessage.createdAt) < new Date(lastLoadedMessage?.createdAt))
										.map(chatMessage => chatMessage.id)

									/**
									 * WARNING:
									 *  - Only remove messages if there is more than one message to be removed,
									 * since sometimes it happens for this setState to run multiple times in a row,
									 * and in case we remove only one message, the chat message list does not scroll
									 * correctly to bottom when the chat is opened.
									 */
									if (messageIdsToRemove.length > 1) {
										chatState.message.removeById(messageIdsToRemove).then(() => chatState.conversationPanel.scrolledBottomList.enableAutoScrollBottom)
										chatState.chat.update({ fullyLoadedAllEarlierMessages: false })
									}
								}
							}

							const resetCursor: ChatEarlierMessagesLoadCursor = {
								page: pageToKeepCached + 1,
								lastLoads: {}
							}

							updatedCursors = resetCursor
						}

						return updatedCursors
					})
				},
				getCachedMessages (page: number): Message[] | null {
					const cursor = chatEarlierMessagesLoadCursor.current

					if (cursor?.lastLoads?.[page]) {
						return _.cloneDeep(cursor.lastLoads[page])
					}

					return null
				},
				loadedInitialMessages (): boolean {
					const cursor = chatEarlierMessagesLoadCursor.current

					if (cursor) {
						return cursor.page > 0
					}

					return false
				}
			},
			get chatLoadingError (): ChatLoadingError {
				return chatLoadingError.current
			},
			setChatLoadingError (newChatLoadingError: Partial<ChatLoadingError>): ChatLoadingError {
				setChatLoadingError(() => {
					return newChatLoadingError
				})

				return chatLoadingError.current
			}
		},
		conversationPanel: {
			textInput: {
				get ref (): React.RefObject<TextInputHandler> {
					return textInputRef
				},
				get value (): string {
					return textInputRef.current?.getCurrentValue() || ""
				},
				clear (): void {
					textInputRef.current?.clear()
				},
				focus (): void {
					runAfterReactRender(() => {
						textInputRef.current?.focus()
					})
				},
				addText (text: string): void {
					textInputRef.current?.addText(text)
				}
			},
			scrolledBottomList: {
				get ref (): React.RefObject<ScrolledBottomListHandler> {
					return scrolledBottomListRef
				},
				enableAutoScrollBottom (): void {
					scrolledBottomListRef.current?.enableAutoScrollBottom()
				},
				disableAutoScrollBottom (): void {
					scrolledBottomListRef.current?.disableAutoScrollBottom()
				},
				lockScrollTop (): void {
					scrolledBottomListRef.current?.lockScrollTop()
				},
				unlockScrollTop (): void {
					scrolledBottomListRef.current?.unlockScrollTop()
				},
				scrollPastBeginning: {
					get isPast (): boolean {
						return scrollPastBeginning
					},
					change: (totalScrollHeight, currentScrollHeight) => {
						const scrollTolerance = 1000
						const isScrollPastBeginning = (totalScrollHeight - scrollTolerance) > currentScrollHeight

						setScrollPastBeginning(isScrollPastBeginning)
					}
				}
			},
			media: {
				add (medias: Media[]): void {
					MediaValidation.validateMedia(medias?.[0], () => {
						setMedias(lastState => ([
							...lastState,
							...medias
						]))
					})
				},
				get current (): Media[] {
					return medias
				},
				clear (): void {
					setMedias([])
				}
			},
			replyMessage: {
				change (messageId: string): void {
					setReplyMessageId(messageId)
				},
				get current (): Message {
					const currentReplyMessage = messages.current[replyMessageId]

					return currentReplyMessage
				},
				clear () {
					setReplyMessageId("")
				}
			}
		},
		activeCampaignContacts: {
			set: (contact) => {
				setAllActiveCampaignContacts(contact)
			},
			getAll: () => {
				return allActiveCampaignContacts
			},
			get currentSelected (): ActiveCampaignContactPickedData | null {
				return selectedActiveCampaignContact.current
			},
			setSelected: async (selectedContact) => {
				await setSelectedActiveCampaignContact(lastState => {
					return {
						...lastState,
						...selectedContact
					}
				})
			}
		}
	}

	ActiveCampaignExternalChatGlobalState.displayName = "ActiveCampaignExternalChatGlobalState"

	return (
		<ActiveCampaignExternalChatGlobalState.Provider
			value={chatState}
		>
			{children}
		</ActiveCampaignExternalChatGlobalState.Provider>
	)
}

export default ActiveCampaignExternalChatGlobalStateProvider
