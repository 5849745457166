import React, { useState } from "react"

import {
	Grid,
	Avatar,
	Typography,
	Tooltip,
	IconButton
} from "@material-ui/core"

import { AttendanceStatus } from "@/protocols/channel"

import useCustomMemo from "@/hooks/useCustomMemo"

import useStyles from "@/components/ACExternalConversationPanel/ConversationHeader/styles"

import { keepOnlyDigits } from "@/utils/phone"
import { formatPhoneNumber } from "@/utils/mask"
import { getPhoneNumber } from "@/utils/contact"

import useCustomStyles from "@/styles/custom"
import { useActiveCampaignExternalChatGlobalStateStore } from "@/store/ActiveCampaignExternalChatGlobalState"
import Divider from "@/components/Divider"
import ManageAttendance from "@/components/ACExternalConversationPanel/ConversationHeader/ManageAttendance"
import { Contacts as ContactsIcon } from "@material-ui/icons"
import PhoneNumberSelectorDialog from "@/pages/ActiveCampaignChatQuickViewPage/PhoneNumberSelector"

const ConversationHeader: React.FC = () => {
	const activeCampaignExternalChatGlobalState = useActiveCampaignExternalChatGlobalStateStore()

	const customClasses = useCustomStyles()
	const classes = useStyles()

	const client = activeCampaignExternalChatGlobalState.chat.current?.client
	const inboxChannelChat = activeCampaignExternalChatGlobalState.chat.current
	const contactPhoneNumber = getPhoneNumber(client)
	const currentChatAttendanceStatus = inboxChannelChat?.attendance?.status as AttendanceStatus
	const isAttendanceStatusSnooze = inboxChannelChat?.status === "snooze"

	const showTitleName = (keepOnlyDigits(client?.nickname || "") === contactPhoneNumber) && (inboxChannelChat?.type !== "group")

	const allActiveCampaginUsableContacts = activeCampaignExternalChatGlobalState.activeCampaignContacts.getAll()
	const canSelectAnotherContact = allActiveCampaginUsableContacts.length > 1

	const [isPhoneSelectorOpened, setIsPhoneSelectorOpened] = useState<boolean>(false)

	const handleOpenPhoneNumberSelector = async () => {
		setIsPhoneSelectorOpened(true)
	}

	const handleClosePhoneNumberSelector = async () => {
		setIsPhoneSelectorOpened(false)
	}

	const handleSavePhoneNumberSelector = async () => {
		handleClosePhoneNumberSelector()
		await activeCampaignExternalChatGlobalState.chat.loadFromServer()
	}

	return useCustomMemo(() => (
		<>
			{inboxChannelChat && (
				<>
					<Grid item xs>
						<Grid
							container
							spacing={2}
							alignItems="center"
						>

							<Grid item>
								<Avatar src={client?.picture_url} />
							</Grid>

							<Grid item xs>
								<Grid
									container
									direction="column"
									alignItems="flex-start"
								>
									<Typography
										variant="h2"
										color="textPrimary"
										className={customClasses.inlineText}
									>
										{client?.nickname}

										{
											showTitleName && (
												<Typography
													variant="caption"
													color="textPrimary"
													className={classes.inlineTextName}
												>
													~{client?.name}
												</Typography>
											)
										}
									</Typography>

									<Typography
										variant="caption"
										color="textPrimary"
										className={customClasses.inlineText}
									>
										{formatPhoneNumber(contactPhoneNumber)}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<Divider orientation="vertical" size={2} />

					{
						canSelectAnotherContact && (
							<Tooltip
								title="Abrir lista de contatos"
							>
								<IconButton
									onClick={handleOpenPhoneNumberSelector}
								>
									<ContactsIcon/>
								</IconButton>
							</Tooltip>
						)
					}

					<Divider orientation="vertical" size={2} />

					<ManageAttendance attendanceStatus={currentChatAttendanceStatus} />

					{/* <Divider orientation="vertical" size={2} />

					<Grid>
						{isAbleToAssignAttendance && <AssignAttendance />}
					</Grid> */}

					{/* {isAttendanceStatusActive && <Snooze handleCancelSnooze={handleCancelSnooze} />} */}

					<PhoneNumberSelectorDialog
						openDialog={isPhoneSelectorOpened}
						getAllContacts={activeCampaignExternalChatGlobalState.activeCampaignContacts.getAll}
						setSelectedContact={activeCampaignExternalChatGlobalState.activeCampaignContacts.setSelected}
						onClose={handleClosePhoneNumberSelector}
						onSave={handleSavePhoneNumberSelector}
						defaultSelectedContact={activeCampaignExternalChatGlobalState.activeCampaignContacts.currentSelected}
					/>
				</>
			)}
		</>
	), [
		currentChatAttendanceStatus,
		isAttendanceStatusSnooze,
		activeCampaignExternalChatGlobalState.chat.current?.status,
		activeCampaignExternalChatGlobalState.chat.current?.title,
		activeCampaignExternalChatGlobalState.chat.current?.client?.picture_key,
		isPhoneSelectorOpened
	])
}

export default ConversationHeader
