import React, { useState } from "react"
import {
	Select,
	MenuItem,
	Typography, Link
} from "@material-ui/core"

import { renderComponent } from "@/utils/node"

import { ActionDialog, Divider } from "@/components"

import {
	ConstructionResources,
	ShortChatBotTrigger,
	FlowSpecificConstructorType
} from "@/protocols/chatBotConstructor"
import { ChatBotFlowTriggerType } from "@/protocols/chatBot"

import CreateWebhook from "@/pages/Admin/Webhooks/CreateWebhook"
import TagAssociationEditorDialog from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Nodes/TriggerNode/TriggerItem/TagAssociatedTriggerItem/TagAssociationEditorDialog"

import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Nodes/TriggerNode/styles"
import { blockLinks } from "@/utils/link"
import FeatureStatusChip from "@/components/FeatureStatusChip"

type TriggerCreatorProps = {
	onSave: (flowTrigger: ShortChatBotTrigger) => void
	constructionResources: ConstructionResources
	loadConstructionResources: () => Promise<ConstructionResources | null>
	loadSpecificConstructionResources: (specificData: FlowSpecificConstructorType) => Promise<Partial<ConstructionResources> | null>
}

type TriggerCreatorType = {
	open: (props: TriggerCreatorProps) => void
}

const TriggerCreator: TriggerCreatorType & React.FC<TriggerCreatorProps> = (props) => {
	const {
		onSave,
		constructionResources,
		loadConstructionResources,
		loadSpecificConstructionResources
	} = props

	const [opened, setOpened] = useState(true)

	const [triggerSelectedValue, setTriggerSelectedValue] = useState<ChatBotFlowTriggerType>("attendance-started-by-client")

	const classes = useStyles()

	const handleClose = () => {
		setOpened(false)
	}

	const handleSelectTriggerChange = (triggerType: ChatBotFlowTriggerType) => {
		setTriggerSelectedValue(triggerType)
	}

	const handleSave = () => {
		if (triggerSelectedValue === "webhook-triggered") {
			CreateWebhook.open({
				onSave: async (data) => {
					await loadConstructionResources()

					const flowTrigger = constructionResources?.triggers?.find(trigger => trigger.type === "webhook-triggered")

					onSave({
						id: flowTrigger?.id as number,
						type: "webhook-triggered",
						webhook_id: data.id
					})
				}
			})
		} else if (triggerSelectedValue === "client-tag-associated") {
			const flowTrigger = constructionResources.triggers.find(trigger => trigger.type === triggerSelectedValue)

			if (flowTrigger) {
				TagAssociationEditorDialog.open({
					saveText: "Salvar",
					onLoadTags: async () => {
						const resources = await loadSpecificConstructionResources("tagOptions")

						if (resources) {
							return resources as ConstructionResources["tagOptions"]
						}
					},
					tags: constructionResources.tagOptions,
					onSave: async (tag) => {
						if (tag) {
							onSave({
								id: flowTrigger?.id as number,
								type: "client-tag-associated",
								tag
							})
						}
					},
					trigger: flowTrigger
				})
			}
		} else {
			const flowTrigger = constructionResources.triggers.find(trigger => trigger.type === triggerSelectedValue)

			if (flowTrigger) {
				onSave(flowTrigger)
			}
		}

		handleClose()
	}

	return (
		<ActionDialog
			saveButtonId="add-new-chat-bot-trigger-save-button"
			id="AddNewChatBotTrigger"
			title="GATILHOS"
			hideCloseButton
			onClose={handleClose}
			openDialog={opened}
			saveText={triggerSelectedValue === "webhook-triggered" ? "CRIAR UM WEBHOOK" : "SALVAR"}
			onSave={handleSave}
			maxWidth="md"
			fullWidth
		>
			<Typography variant="body1" color="textPrimary">
				Selecione o gatilho para acionar o Chatbot. <Link href={blockLinks.howChatBotTriggersWork} target="_blank">Saiba mais</Link>
			</Typography>

			<Divider size={2} orientation="horizontal" />

			<Select
				value={triggerSelectedValue}
				defaultValue={triggerSelectedValue}
				variant="outlined"
				fullWidth
				onChange={(event) => handleSelectTriggerChange(event.target.value as ChatBotFlowTriggerType)}
				classes={{
					select: classes.select
				}}
			>
				{constructionResources.triggers.map((trigger) => {
					return (
						<MenuItem
							key={trigger.type}
							value={trigger.type}
							style={{
								display: "flex"
							}}
						>
							{trigger.description}

							{trigger.type === "client-tag-associated" && (
								<>
									<Divider orientation="vertical" size={1} />

									<FeatureStatusChip status="Novo" />
								</>
							)}
						</MenuItem>
					)
				})}
			</Select>

			<Divider size={2} orientation="horizontal" />

		</ActionDialog>
	)
}

TriggerCreator.open = (props: TriggerCreatorProps) => {
	renderComponent(
		"trigger-creator",
		<TriggerCreator
			{...props}
		/>
	)
}

export default TriggerCreator
