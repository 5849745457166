import React from "react"
import { IconButton } from "@material-ui/core"
import {
	AttachFile as AttachFileIcon
} from "@material-ui/icons"

import useStyles from "@/pages/Attendance/Chat/ConversationPanel/Input/FileInput/styles"
import MediaService, { Media } from "@/services/Media"

type FileInputProps = {
	onMedia: (files: Media[]) => void
	disabled?: boolean
}

const FileInput: React.FC<FileInputProps> = (props) => {
	const { onMedia, disabled } = props

	const classes = useStyles()

	const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event?.target

		const files = inputElement?.files

		if (files) {
			const filesAdapted = Array.from(files).map((file) => MediaService.adaptFile(file))

			onMedia(filesAdapted)
		}

		inputElement.value = ""
	}

	return (
		<IconButton
			component="label"
			disabled={disabled}
		>
			<AttachFileIcon />

			<input
				type="file"
				className={classes.input}
				onChange={handleChange}
				multiple={true}
			/>

		</IconButton>
	)
}

export default FileInput
