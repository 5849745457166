import React, { useEffect, useState } from "react"

import useStyles from "@/pages/ActiveCampaignChatQuickViewPage/styles"

import { Button, Grid, Typography } from "@material-ui/core"
import Zoid from "zoid"
import ACExternalConversationPanel from "@/components/ACExternalConversationPanel"
import { Loading } from "@/components"
import { ChatLoadingError, useActiveCampaignExternalChatGlobalStateStore } from "@/store/ActiveCampaignExternalChatGlobalState"
import useDidMount from "@/hooks/useDidMount"
import useActiveCampaignChatQuickView, { ActiveUsageContext } from "@/hooks/useActiveCampaignChatQuickView"
import SocketService from "@/services/Socket"
import useSocket from "@/hooks/useSocket"
import useActiveWindowListener from "@/hooks/useActiveWindowListener"
import useThrottledSound from "@/hooks/useThrottledSound"
import newMessageSound from "@/assets/sounds/new-message.mp3"
import useWABAChat from "@/@integrations/WABA/hooks/useWABAChat"
import { useParams } from "react-router-dom"
import PhoneNumberSelectorDialog from "@/pages/ActiveCampaignChatQuickViewPage/PhoneNumberSelector/index"
import { formatPhoneNumber } from "@/utils/mask"

import logoImg from "@/assets/images/logos/letalk-small-logo-purple.svg"

const ActiveCampaignChatQuickViewPage: React.FC = () => {
	const classes = useStyles()
	const [isChatOpened, setIsChatOpened] = useState<boolean>(false)
	const [isPhoneSelectorOpened, setIsPhoneSelectorOpened] = useState<boolean>(false)
	const [isSetupLoaded, setIsSetupLoaded] = useState<boolean>(false)
	const [isThereAnyErrorOnChat, setIsThereAnyErrorOnChat] = useState<boolean>(false)
	const [chatOpeningFeedbackText, setChatOpeningFeedbackText] = useState<string>("")

	const params = useParams<{ active_usage_context: ActiveUsageContext }>()
	const activeUsageContext = params?.active_usage_context || "contact"

	const activeCampaignExternalChatGlobalState = useActiveCampaignExternalChatGlobalStateStore()
	const activeCampaignChatQuickView = useActiveCampaignChatQuickView()
	const socket = useSocket()
	const wabaChat = useWABAChat()

	const activeWindowListener = useActiveWindowListener()
	const notificationThrottledSound = useThrottledSound(newMessageSound)

	const allActiveCampaginUsableContacts = activeCampaignExternalChatGlobalState.activeCampaignContacts.getAll()
	const selectedActiveCampaginContact = activeCampaignExternalChatGlobalState.activeCampaignContacts.currentSelected

	const isChatLoading = !activeCampaignExternalChatGlobalState.chat.chatLoadingError && activeCampaignExternalChatGlobalState.chat.loadingFromServer

	const makeAudioNotification = () => {
		notificationThrottledSound.playSound(3000)
	}

	const getChatOpeningFeedbackText = (): string => {
		const selectedPhoneNumber = formatPhoneNumber(selectedActiveCampaginContact?.phone)
		const numberInfoInFeedback = selectedActiveCampaginContact?.phone ? ` para o número ${selectedPhoneNumber}` : ""

		const sucessMessage = `Chat encontrado${numberInfoInFeedback}, clique no botão abaixo para abrir o chat`

		const chatLoadingError = activeCampaignExternalChatGlobalState.chat.chatLoadingError

		if (!chatLoadingError) {
			return sucessMessage
		}

		const chatErrorToFeedbackText: Record<Exclude<ChatLoadingError, null>, string> = {
			ChatNotFound: `Chat não encontrado${numberInfoInFeedback}, é necessário iniciar uma conversa com o contato dentro da Letalk`,
			ClientNotFound: `Contato não encontrado${numberInfoInFeedback}, é necessário criar um contato na Letalk e iniciar uma conversa`,
			GenericError: `Erro ao carregar chat${numberInfoInFeedback}, verifique se o plugin "RECEBER DADOS" está conectado e configurado corretamente na letalk para tentar novamente`,
			ActiveCampaignApiPluginDisabled: `Erro ao carregar chat${numberInfoInFeedback}, verifique se o plugin "ENVIAR DADOS" está conectado e configurado corretamente na letalk para tentar novamente`
		}

		const chatOpeningFeedbackText = chatErrorToFeedbackText[chatLoadingError] || sucessMessage
		return chatOpeningFeedbackText
	}

	const setupSocketEvents = async () => {
		socket.onMessageStatusChanged(async updatedMessage => {
			const isEventFromOpenedChat = updatedMessage.inboxChannelChatId === activeCampaignExternalChatGlobalState.chat.current?.id

			if (isEventFromOpenedChat) {
				activeCampaignExternalChatGlobalState.message.updateById(updatedMessage.id, {
					status: updatedMessage.status,
					...(updatedMessage.status === "sent" && { createdAt: updatedMessage.createdAt }),
					...(Boolean(updatedMessage.content) && { content: updatedMessage.content }),
					...(updatedMessage.error && { inboxChannelChatMessageLog: { error: updatedMessage.error } })
				})
			}
		})

		socket.onMessageDeleted(async message => {
			const isEventFromOpenedChat = message.inboxChannelChatId === activeCampaignExternalChatGlobalState.chat.current?.id

			if (isEventFromOpenedChat) {
				activeCampaignExternalChatGlobalState.message.updateById(message.id, {
					content: "",
					deletedAt: String(new Date())
				})
			}
		})

		socket.onMessageReaction(async reactions => {
			activeCampaignExternalChatGlobalState.message.updateById(reactions.messageId, {
				extraData: {
					reactions: reactions.reactions
				}
			})
		})

		socket.onEditedMessageReceived(async message => {
			const isEventFromOpenedChat = message.inboxChannelChatId === activeCampaignExternalChatGlobalState.chat.current?.id

			if (isEventFromOpenedChat) {
				activeCampaignExternalChatGlobalState.message.updateById(message.id, {
					content: message.content,
					extraData: {
						editedMessage: message.extraData?.editedMessage
					}
				})
			}
		})

		socket.onClientDataChanged(async client => {
			const isActualClient = activeCampaignExternalChatGlobalState.chat.current?.client.id === client.id
			if (isActualClient) {
				activeCampaignExternalChatGlobalState.chat.update({
					client
				})
			}
		})

		socket.onChatAttendanceTaken(attendance => {
			const isEventFromOpenedChat = attendance.inboxChannelChatId === activeCampaignExternalChatGlobalState.chat.current?.id

			if (isEventFromOpenedChat) {
				activeCampaignExternalChatGlobalState.chat.update({
					status: "on-going",
					attendance: {
						userName: attendance.userName,
						userId: attendance.userId,
						assignTeamId: attendance.assignTeamId,
						assignUserId: attendance.assignUserId,
						assignmentQueueType: attendance.assignmentQueueType,
						status: "active"
					}
				})
			}
		})

		socket.onChatAttendanceAssigned(attendance => {
			const isEventFromOpenedChat = attendance.inboxChannelChatId === activeCampaignExternalChatGlobalState.chat.current?.id

			if (isEventFromOpenedChat) {
				activeCampaignExternalChatGlobalState.chat.update({
					status: "queue",
					attendance: {
						userName: attendance.userName,
						userId: attendance.userId,
						assignTeamId: attendance.assignTeamId,
						assignUserId: attendance.assignUserId,
						assignmentQueueType: attendance.assignmentQueueType,
						status: "waiting"
					}
				})
			}
		})

		socket.onChatAttendanceFinished(attendance => {
			const isEventFromOpenedChat = attendance.inboxChannelChatId === activeCampaignExternalChatGlobalState.chat.current?.id

			if (isEventFromOpenedChat) {
				activeCampaignExternalChatGlobalState.chat.update({
					status: "archived",
					attendance: {
						userName: "",
						userId: null,
						assignTeamId: null,
						assignUserId: null,
						assignmentQueueType: null,
						status: "finished"
					}
				})
			}
		})

		socket.onNewChatAttendanceNotification(notification => {
			const isEventFromOpenedChat = notification.inboxChannelChatId === activeCampaignExternalChatGlobalState.chat.current?.id

			if (isEventFromOpenedChat) {
				activeCampaignExternalChatGlobalState.message.add(notification)
			}
		})

		socket.onNewMessage(async message => {
			const isEventFromOpenedChat = message.inboxChannelChatId === activeCampaignExternalChatGlobalState.chat.current?.id

			if (isEventFromOpenedChat) {
				await activeCampaignExternalChatGlobalState.message.add(message)

				activeCampaignExternalChatGlobalState.message.setRead()

				const isMessageSentByCustomer = message.sentByCustomer

				const isNotActiveWindowMessageNotification = !isMessageSentByCustomer && !activeWindowListener.active

				const chat = activeCampaignExternalChatGlobalState.chat.current
				const isChatBotAttendance = chat?.status === "chat-bot"
				const notifyByAudio = isNotActiveWindowMessageNotification && !isChatBotAttendance

				if (notifyByAudio) {
					makeAudioNotification()
				}
			}
		})

		socket.onMessageUpdated(async message => {
			const isEventFromOpenedChat = message.inboxChannelChatId === activeCampaignExternalChatGlobalState.chat.current?.id

			if (isEventFromOpenedChat) {
				await activeCampaignExternalChatGlobalState.message.updateById(message.id, {
					type: message?.type,
					content: message?.content,
					caption: message?.caption
				})
			}
		})

		wabaChat.setupChatListeners({
			getChatOnStore: (chatId) => {
				if (chatId === activeCampaignExternalChatGlobalState.chat.current?.id) {
					return activeCampaignExternalChatGlobalState.chat.current
				}
			},
			updateChatOnStore: async (chatId, data) => {
				if (chatId === activeCampaignExternalChatGlobalState.chat.current?.id) {
					await activeCampaignExternalChatGlobalState.chat.update(data)
				}
			}
		})
	}

	const handleOpenChat = async () => {
		setIsChatOpened(true)
	}

	const handleOpenPhoneNumberSelector = async () => {
		setIsPhoneSelectorOpened(true)
	}

	const handleClosePhoneNumberSelector = async () => {
		setIsPhoneSelectorOpened(false)
	}

	const handleSavePhoneNumberSelector = async () => {
		handleClosePhoneNumberSelector()
		await activeCampaignExternalChatGlobalState.chat.loadFromServer()
	}

	const initializeByContext = async (context: ActiveUsageContext) => {
		setIsSetupLoaded(false)

		const contextToZoidTag: Record<ActiveUsageContext, string> = {
			contact: "Inbox Letalk",
			deal: "Inbox da Letalk"
		}
		const zoidTag = contextToZoidTag[context]

		/**
		 * This snippet must be the first to be executed in order to populate Window with Active data
		 */
		Zoid.create({
			url: window.location.href,
			tag: zoidTag
		})

		const quickViewPluginCredentials = await activeCampaignChatQuickView.getQuickViewPluginCredentials()

		activeCampaignExternalChatGlobalState.chat.setChatLoadingError(quickViewPluginCredentials.chatLoadingError)

		if (quickViewPluginCredentials.instanceId) {
			activeCampaignExternalChatGlobalState.representantUser.setup({
				userId: Number(quickViewPluginCredentials?.representantUserId),
				userName: quickViewPluginCredentials?.representantUserName,
				authToken: quickViewPluginCredentials?.representantUserAuthToken,
				instanceId: Number(quickViewPluginCredentials?.instanceId),
				inboxChannelUserChatSettings: quickViewPluginCredentials?.inboxChannelUserChatSettings
			})

			activeCampaignExternalChatGlobalState.activeCampaignContacts.set(quickViewPluginCredentials.allContacts)
			activeCampaignExternalChatGlobalState.activeCampaignContacts.setSelected(quickViewPluginCredentials.mainContact)

			await SocketService.setup(quickViewPluginCredentials?.instanceId, quickViewPluginCredentials?.representantUserAuthToken)

			setupSocketEvents()
		} else {
			activeCampaignExternalChatGlobalState.chat.setChatLoadingError("GenericError")
		}

		if (quickViewPluginCredentials.mainContact.phone) {
			await activeCampaignExternalChatGlobalState.chat.loadFromServer()
		}

		setIsSetupLoaded(true)
	}

	useDidMount(() => {
		initializeByContext(activeUsageContext)
	})

	useEffect(() => {
		const isThereIsAnyErrorOnChat = Boolean(activeCampaignExternalChatGlobalState.chat.chatLoadingError)

		setIsThereAnyErrorOnChat(isThereIsAnyErrorOnChat)
		setChatOpeningFeedbackText(getChatOpeningFeedbackText())

		const willAutomaticallyOpenChat = !isThereIsAnyErrorOnChat
		setIsChatOpened(willAutomaticallyOpenChat)
	}, [activeCampaignExternalChatGlobalState.activeCampaignContacts.currentSelected?.id, activeCampaignExternalChatGlobalState.chat.chatLoadingError, isSetupLoaded])

	return (
		<Grid
			container
			className={classes.container}
		>
			<Loading loading={!isSetupLoaded || isChatLoading}>
				{
					isChatOpened ? (
						<ACExternalConversationPanel></ACExternalConversationPanel>
					) : (
						<Grid
							item
							alignContent="center"
							className={classes.chatCoverPage}
						>
							<Grid
								container
								direction="column"
							>
								<img
									alt="Letalk"
									src={logoImg}
									className={classes.logo}
								/>

								<Typography
									align="center"
									className={classes.feedbackText}
								>
									{ chatOpeningFeedbackText }
								</Typography>

								{
									!isThereAnyErrorOnChat && (
										<Button
											color="primary"
											variant="contained"
											type="submit"
											className={classes.openChatButton}
											onClick={handleOpenChat}
										>
											Abrir chat
										</Button>
									)
								}

								{
									allActiveCampaginUsableContacts.length > 1 && (
										<Button
											color="primary"
											variant="contained"
											type="submit"
											className={classes.openChatButton}
											onClick={handleOpenPhoneNumberSelector}
										>
											Alterar contato
										</Button>
									)
								}
							</Grid>
						</Grid>
					)
				}
			</Loading>

			<PhoneNumberSelectorDialog
				openDialog={isPhoneSelectorOpened}
				getAllContacts={activeCampaignExternalChatGlobalState.activeCampaignContacts.getAll}
				setSelectedContact={activeCampaignExternalChatGlobalState.activeCampaignContacts.setSelected}
				onClose={handleClosePhoneNumberSelector}
				onSave={handleSavePhoneNumberSelector}
				defaultSelectedContact={activeCampaignExternalChatGlobalState.activeCampaignContacts.currentSelected}
			/>
		</Grid>
	)
}

export default ActiveCampaignChatQuickViewPage
