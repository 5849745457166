import React, { useState } from "react"

import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	Grid,
	IconButton,
	ThemeProvider,
	Typography,
	FormControl,
	Select,
	MenuItem,
	List,
	ListItem, DialogTitle
} from "@material-ui/core"
import {
	Check,
	Close
} from "@material-ui/icons"
import { DotLottieReact } from "@lottiefiles/dotlottie-react"

import {
	Loading,
	Notification
} from "@/components"
import UpsellQuantitySuccessfullyDialog from "@/components/UpsellDialog/UpsellQuantityDialog/UpsellQuantitySuccessfullyDialog"

import { renderComponent } from "@/utils/node"
import { letalkLinks } from "@/utils/link"
import { convertCentsInReals } from "@/utils/currencyFormatter"

import AccountService from "@/services/Account"
import ErrorHandlerService from "@/services/ErrorHandler"

import Rocket from "@/assets/lotties/rocket.lottie"

import useDidMount from "@/hooks/useDidMount"

import UpsellQuantityDialogSkeleton from "@/skeletons/UpsellQuantityDialogSkeleton"

import {
	PlanAddons,
	UpsellQuantityInfo,
	UpsellQuantityType
} from "@/protocols/planAddons"

import theme from "@/styles/theme"
import useStyles from "@/components/UpsellDialog/UpsellQuantityDialog/styles"
import { ErrorType } from "@/hooks/useValidation"

type UpsellQuantityDialogProps = {
	type: UpsellQuantityType
	actualQuantity: number
	isUserReachSubscriptionLimit: boolean
}

type UpsellHandlerDialogType = {
	open: (props: UpsellQuantityDialogProps) => void
}

type UpsellQuantityTypeToUpsellContent = Record<UpsellQuantityType, {
	title: string
	description: string
	benefits: React.ReactNode[]
	confirmButtonTitle: string
	cancelButtonTitle: string
	media: React.ReactNode
	selectText: string
}>

const UpsellQuantityDialog: UpsellHandlerDialogType & React.FC<UpsellQuantityDialogProps> = (props) => {
	const {
		type,
		actualQuantity,
		isUserReachSubscriptionLimit
	} = props

	const [isDialogOpen, setIsDialogOpen] = useState(true)
	const [createPlanAddonloading, setCreatePlanAddonloading] = useState(false)
	const [loading, setLoading] = useState(false)
	const [success, setSuccess] = useState(false)
	const [planAddons, setPlanAddons] = useState<PlanAddons<"quantity">>([])

	const [selectedUpsellPlanAddon, setSelectedUpsellPlanAddon] = useState<UpsellQuantityInfo>({} as UpsellQuantityInfo)

	const classes = useStyles()

	const handleGetPlanAddons = async () => {
		setLoading(true)

		try {
			const response = await AccountService.retrievelAllPlanAddonsByUpsellType<"quantity">(type, "quantity")

			setPlanAddons(response)

			const defaultSelectedAddon = response.find((addon) => addon.quantity === 2)

			if (defaultSelectedAddon) {
				setSelectedUpsellPlanAddon(defaultSelectedAddon)
			}
		} catch (error) {
			Notification.error({
				message: "Houve um erro ao buscar os adicionais!"
			})

			ErrorHandlerService.handle(error as ErrorType)
		}

		setLoading(false)
	}

	const handleCreatePlanAddonInAccount = async () => {
		setCreatePlanAddonloading(true)

		try {
			await AccountService.createPlanAddonsInAccount({
				planAddon: {
					id: selectedUpsellPlanAddon.planAddonId,
					name: selectedUpsellPlanAddon.displayName,
					priceInCents: selectedUpsellPlanAddon.priceInCents
				},
				createdBy: "user",
				isUserReachSubscriptionLimit,
				upsellType: type
			})

			setSuccess(true)

			Notification.success({
				message: "Contratação realizada com sucesso!"
			})
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)

			Notification.error({
				message: "Houve um erro ao contratar o adicional!"
			})

			setSuccess(false)
		}

		setCreatePlanAddonloading(false)
	}

	const handleCloseDialog = () => {
		setIsDialogOpen(false)
	}

	const handleSaveDialog = async () => {
		await handleCreatePlanAddonInAccount()
	}

	const handleSelectUpsellPlanAddon = (planAddonId: number) => {
		const upsell = planAddons?.find(upsell => upsell.planAddonId === planAddonId)

		if (upsell) {
			setSelectedUpsellPlanAddon(upsell)
		}
	}

	const getUpsellContent = () => {
		const upsellTypeToContent: UpsellQuantityTypeToUpsellContent = {
			user: {
				title: isUserReachSubscriptionLimit ? "Seu limite de atendentes foi atingindo" : "Antecipe suas necessidades de atendimento",
				description: isUserReachSubscriptionLimit ? "Você atingiu o limite de atendentes simultâneos. Expanda sua capacidade de atendimento e melhore a experiência dos seus clientes. Adicione mais atendentes agora e otimize sua operação!"
					: "Evite imprevistos e contrate mais atendentes agora mesmo. Garanta que sua operação continue fluindo perfeitamente. Adicione mais atendentes agora e otimize sua operação!",
				benefits: [
					<Typography variant="body1" key={1}><strong>Atenda mais clientes</strong> e reduza o tempo de espera.</Typography>,
					<Typography variant="body1" key={2}><strong>Garanta agilidade</strong> com uma equipe reforçada.</Typography>,
					<Typography variant="body1" key={3}><strong>Aumente a produtividade</strong> com mais recursos disponíveis.</Typography>,
					<Typography variant="body1" key={4}><strong>Adapte-se rapidamente</strong> às demandas do seu negócio.</Typography>
				],
				confirmButtonTitle: "ATIVAR ATENDENTES AGORA",
				cancelButtonTitle: "Não tenho interesse agora",
				media: (
					<>
						<DotLottieReact
							src={Rocket}
							speed={0.3}
							loop={true}
							autoplay={true}
							segment={[21, 40]}
							style={{
								position: "absolute",
								top: 190,
								left: 50,
								width: "200%",
								transform: "translate(-34%, -30%)"
							}}
							mode="bounce"
						/>
					</>
				),
				selectText: "Selecione a quantidade de atendentes:"
			}
		}

		return upsellTypeToContent[type]
	}

	const upsellContent = getUpsellContent()

	useDidMount(() => {
		handleGetPlanAddons()
	})

	if (success) {
		return (
			<UpsellQuantitySuccessfullyDialog
				upsellQuantityInfo={selectedUpsellPlanAddon}
				upsellQuantityType={type}
				actualQuantity={actualQuantity}
				onClose={handleCloseDialog}
			/>
		)
	}

	return (
		<ThemeProvider theme={theme}>
			<Dialog
				open={isDialogOpen}
				onClose={handleCloseDialog}
				maxWidth="md"
				fullWidth
				PaperProps={{
					className: classes.fullDialog
				}}
			>
				<Loading loading={loading} customLoadingElement={<UpsellQuantityDialogSkeleton />}>
					<Grid
						container
						className={classes.fullDialog}
					>
						<Grid
							item
							xs={8}
						>
							<Grid
								container
								direction="column"
								justifyContent="space-between"
								className={classes.fullDialog}
							>
								<Grid
									item
									style={{
										flex: 1
									}}
								>
									<DialogTitle>
										<Grid
											container
											direction="column"
											spacing={2}
										>
											<Grid
												item
											>
												<Typography variant="h3" className={classes.dialogTitle}>
													{upsellContent.title}
												</Typography>
											</Grid>
											<Grid
												item
											>
												<Typography className={classes.dialogDescription}>
													{upsellContent.description}
												</Typography>
											</Grid>
										</Grid>
									</DialogTitle>
								</Grid>
								<Grid
									item
									xs="auto"
									style={{
										flex: 2
									}}
								>
									<DialogContent className={classes.dialogContent}>
										<Grid
											container
											direction="column"
											spacing={2}
										>
											<Grid
												item
											>
												<List>
													{upsellContent.benefits.map((benefit, index) => (
														<ListItem key={index} className={classes.benefitListItem}>
															<Check className={classes.benefitItemIcon} />
															{benefit}
														</ListItem>
													))}
												</List>
											</Grid>

											<Grid
												item
											>
												<Grid
													container
													direction="column"
												>
													<Grid
														item
													>
														<Grid
															container
															alignItems="center"
														>
															<Typography variant="h6" className={classes.planAddonsInformationText}>
																{upsellContent.selectText}
															</Typography>

															<FormControl style={{
																marginLeft: 8,
																minWidth: 20
															}}>
																<Select
																	id="attendants-quantity-select"
																	value={selectedUpsellPlanAddon.planAddonId}
																	onChange={(event) => handleSelectUpsellPlanAddon(Number(event.target.value))}
																	style={{
																		textAlign: "end"
																	}}
																>
																	{planAddons.map(item => (
																		<MenuItem
																			value={item.planAddonId}
																			key={item.planAddonId}
																		>
																			{item.quantity}
																		</MenuItem>
																	))}
																</Select>
															</FormControl>
														</Grid>
													</Grid>

													<Grid
														item
													>
														<Typography variant="h6" className={classes.planAddonsInformationText}>
															Por apenas: {convertCentsInReals(selectedUpsellPlanAddon.priceInCents)}
														</Typography>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</DialogContent>
								</Grid>
								<Grid
									item
								>
									<DialogActions className={classes.dialogActions}>
										<Grid
											container
											spacing={1}
										>
											<Grid
												item
												xs={12}
											>
												<Grid
													container
													justifyContent="flex-start"
												>
													<Grid
														item
														xs={10}
													>
														<Button
															variant="contained"
															endIcon={createPlanAddonloading ? <CircularProgress size={20} color={"inherit"} /> : null}
															className={classes.dialogActionPrimaryButton}
															disabled={createPlanAddonloading || !selectedUpsellPlanAddon?.planAddonId}
															onClick={handleSaveDialog}
														>
															{upsellContent.confirmButtonTitle}
														</Button>
													</Grid>

													<Grid
														item
														xs={10}
													>
														<Typography
															className={classes.termsOfUse}
															variant="caption"
															color="textSecondary"
														>
															Ao continuar, você concorda com os <a className={classes.termsOfUseLink} href={letalkLinks.notionTermsOfService} target="_blank" rel="noopener noreferrer">termos de uso</a> e a contratação do adicional de {selectedUpsellPlanAddon.quantity} atendente(s) por {convertCentsInReals(selectedUpsellPlanAddon.priceInCents)} que será cobrada na sua próxima fatura.
														</Typography>
													</Grid>
												</Grid>
											</Grid>

											<Grid
												item
												xs={10}
											>
												<Grid
													container
													style={{
														justifyContent: "center"
													}}
												>
													<Button
														variant="text"
														className={classes.dialogActionSecondaryButton}
														onClick={handleCloseDialog}
													>
														{upsellContent.cancelButtonTitle}
													</Button>
												</Grid>
											</Grid>
										</Grid>
									</DialogActions>
								</Grid>
							</Grid>
						</Grid>
						<Grid
							xs={4}
							className={classes.imageContainer}
							item
						>
							{upsellContent.media}

							<IconButton className={classes.closeButton} onClick={handleCloseDialog}>
								<Close />
							</IconButton>
						</Grid>
					</Grid>
				</Loading>
			</Dialog>
		</ThemeProvider>
	)
}

UpsellQuantityDialog.open = (props: UpsellQuantityDialogProps) => {
	renderComponent(
		props.type,
		<UpsellQuantityDialog
			{...props}
		/>
	)
}

export default UpsellQuantityDialog
