import React, { useState } from "react"
import { Checkbox, FormControlLabel, Grid, Typography } from "@material-ui/core"
import { renderComponent } from "@/utils/node"
import { ActionDialog } from "@/components"
import useDidMount from "@/hooks/useDidMount"
import { isMoreOrEqualThanDaysAgo } from "@/utils/time"
import StorageService from "@/services/Storage"

type WalletBalanceBalanceOptionsData = {
	checked: boolean
	createdAt: string
}

type WalletBalanceNotificationProps = {
	instanceId: number
}
type NotificationDialogType = {
	open: (props: WalletBalanceNotificationProps) => void
}

const WalletBalanceNotification: NotificationDialogType & React.FC<WalletBalanceNotificationProps> = (props) => {
	const { instanceId } = props

	const [openBalanceDialog, setOpenBalance] = useState(false)
	const [balanceOptions, setBalanceOptions] = useState<WalletBalanceBalanceOptionsData>({
		checked: false,
		createdAt: ""
	})

	const removeWalletBalanceNotificationOptionFromLocalStorage = () => {
		StorageService.delete(StorageService.mountKey(StorageService.reservedKeys.INBOX_WABA_WALLET_BALANCE_NOTIFICATION, String(instanceId)))
	}
	const getWalletBalanceNotificationOptionFromLocalStorage = (): WalletBalanceBalanceOptionsData | null => {
		const notifyWalletBalanceStore = StorageService.get<WalletBalanceBalanceOptionsData>(StorageService.mountKey(StorageService.reservedKeys.INBOX_WABA_WALLET_BALANCE_NOTIFICATION, String(instanceId)))
		return notifyWalletBalanceStore || null
	}

	const setWalletBalanceNotificationOptionOnLocalStorage = (options: WalletBalanceBalanceOptionsData) => {
		StorageService.set<WalletBalanceBalanceOptionsData>(StorageService.mountKey(StorageService.reservedKeys.INBOX_WABA_WALLET_BALANCE_NOTIFICATION, String(instanceId)), options)
	}
	const resetLowWalletBalanceNotification = () => {
		removeWalletBalanceNotificationOptionFromLocalStorage()
		setOpenBalance(true)
	}

	const canShowLowWalletBalanceNotification = () => {
		const storedNotificationConfiguration = getWalletBalanceNotificationOptionFromLocalStorage()

		if (storedNotificationConfiguration) {
			if (isMoreOrEqualThanDaysAgo(new Date(storedNotificationConfiguration.createdAt), 1)) {
				resetLowWalletBalanceNotification()
			} else {
				setBalanceOptions(storedNotificationConfiguration)
				setOpenBalance(!storedNotificationConfiguration.checked)
			}
		} else {
			setOpenBalance(true)
		}
	}

	const updateWalletBalanceNotificationOption = (newChecked: boolean): void => {
		const options: WalletBalanceBalanceOptionsData = {
			checked: newChecked,
			createdAt: new Date().toString()
		}

		setWalletBalanceNotificationOptionOnLocalStorage(options)
		setBalanceOptions(options)
	}

	const handleSave = (): void => {
		setOpenBalance(false)
	}

	useDidMount(() => {
		canShowLowWalletBalanceNotification()
	})

	return (
		<ActionDialog
			title="SEUS CRÉDITOS ESTÃO ACABANDO!"
			openDialog={openBalanceDialog}
			fullWidth={true}
			onSave={handleSave}
			saveText="Estou ciente"
			hideCloseIcon
			hideCloseButton
		>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography>
						Para manter a comunicação ativa com seus contatos, solicite ao seu gestor uma recarga. Evite interrupções na sua comunicação.
					</Typography>
				</Grid>

				<Grid item xs={12}>
					<FormControlLabel
						control={
							<Checkbox
								checked={balanceOptions.checked || false}
								onChange={({ target }) => updateWalletBalanceNotificationOption(target.checked)}
							/>
						}
						label="Mostrar novamente amanhã"
					/>
				</Grid>
			</Grid>
		</ActionDialog>
	)
}

WalletBalanceNotification.open = (props: WalletBalanceNotificationProps) => {
	renderComponent("low-wallet-balance-dialog", <WalletBalanceNotification {...props} />)
}

export default WalletBalanceNotification
