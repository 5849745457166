import React, { useState } from "react"
import {
	Grid,
	Typography,
	Button,
	FormControlLabel,
	RadioGroup,
	Radio
} from "@material-ui/core"
import {
	ArrowDownward as ArrowDownIcon,
	CropFreeOutlined as GenerateQRCodeIcon
} from "@material-ui/icons"

import {
	Portlet,
	Divider,
	ActionDialog
} from "@/components"

import { ConnectionFlowComponentDefaultProps } from "@/@integrations/Whatsapp/protocols/connectionFlow"
import { IProductMessageMetrics } from "@/protocols/metrics"
import StorageService from "@/services/Storage"

import QRCodeInstruction from "@/@integrations/Whatsapp/components/WhatsappConnectionFlow/QRCodeInstruction"

import useWhatsappConnectionFlowStyles from "@/@integrations/Whatsapp/components/WhatsappConnectionFlow/styles"
import useStyles from "@/@integrations/Whatsapp/components/WhatsappConnectionFlow/GenerateQRCode/styles"

import { ErrorType } from "@/hooks/useValidation"
import useDidMount from "@/hooks/useDidMount"
import usePageEvent from "@/hooks/usePageEvent"

import { useGlobalStateStore } from "@/store/GlobalState"

import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"
import MetricsService from "@/services/Metrics"
import { Alert } from "@material-ui/lab"

type GenerateQRCodeProps = ConnectionFlowComponentDefaultProps & {
	startQRCodeGeneration: () => Promise<boolean>
}

const GenerateQRCode: React.FC<GenerateQRCodeProps> = (props) => {
	const {
		startQRCodeGeneration,
		goToNextStep
	} = props

	const globalStateStore = useGlobalStateStore()
	const classes = useStyles()
	const pageEvent = usePageEvent()
	const whatsappConnectionFlowClasses = useWhatsappConnectionFlowStyles()
	const [openDeactivatedAccountDialog, setOpenDeactivatedAccountDialog] = useState(false)
	const [queueMetrics, setQueueMetrics] = useState({} as IProductMessageMetrics["queueMetrics"])
	const hasEnqueuedMessages = queueMetrics?.enqueuedMessages > 0 || queueMetrics?.sendingMessages > 0
	const [openDialog, setOpenDialog] = useState(false)
	const [selectedQueueAction, setSelectedQueueAction] = useState("clearQueuesMessages")

	const handleOpenDeactivatedAccountDialog = () => {
		setOpenDeactivatedAccountDialog(true)
	}

	const handleCloseDeactivatedAccountDialog = () => {
		setOpenDeactivatedAccountDialog(false)
	}

	const handleGenerateQRCode = () => {
		const isSubscriptionExpired = globalStateStore.instance?.subscriptionData?.tolerance_date && new Date(globalStateStore.instance?.subscriptionData?.tolerance_date) < new Date()

		if (isSubscriptionExpired) {
			handleOpenDeactivatedAccountDialog()
		} else {
			startQRCodeGeneration()
			goToNextStep()
		}
	}

	const onClose = () => {
		setOpenDialog(false)
	}

	const handleConfirmChannelQueueAction = async () => {
		if (selectedQueueAction === "clearQueuesMessages") {
			try {
				await ApiService.post(`/channels/${globalStateStore.currentChannel?.integrationId}/queues/all/clear/waiting`)

				/**
				 * Workaround to force 'src/pages/Admin/Dashboard/Metrics/ProductMessageMetrics/index.tsx'
				 * component to reload its metrics. We do that because 'ProductMessageMetrics' component
				 * is far away from here.
				 */
				pageEvent.emit("ChannelQueueCleaned")
			} catch (error) {
				ErrorHandlerService.handle(error as ErrorType)
			}
		}
		onClose()
		handleGenerateQRCode()
	}
	const instanceId = StorageService.get(StorageService.reservedKeys.INBOX_INSTANCE_ID) as string
	const isAbleGenerateQRCode = Boolean(instanceId)

	const getMetrics = async () => {
		try {
			const queueMetrics = await MetricsService.getQueueMetrics()

			setQueueMetrics(queueMetrics)
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
		}
	}

	const handleChannelQueue = () => {
		setOpenDialog(hasEnqueuedMessages)
	}
	const handleSelectedFilter = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = target
		if (value !== selectedQueueAction) {
			setSelectedQueueAction(value)
		}
	}

	useDidMount(() => {
		getMetrics()
	})

	return (
		<>
			{!isAbleGenerateQRCode && <Alert severity="error" icon={false}>
				Estamos enfrentando uma instabilidade, mas estamos confiantes de que resolveremos esse problema o mais rápido possível. Agradecemos sua compreensão e pedimos que tente novamente em breve.
			</Alert>}

			<Divider orientation="horizontal" size={2} />
			<Portlet
				className={whatsappConnectionFlowClasses.qrCodePortlet}
			>
				<ActionDialog
					title="Conta desativada"
					openDialog={openDeactivatedAccountDialog}
					onClose={handleCloseDeactivatedAccountDialog}
					fullWidth
				>
					<Typography style={{ alignItems: "center", verticalAlign: "baseline" }}>
						A sua conta está desativada, procure nosso financeiro para maiores detalhes.
					</Typography>
				</ActionDialog>

				<ActionDialog
					title="ATENÇÃO"
					openDialog={openDialog}
					fullWidth
					cancelText="Cancelar"
					onClose={onClose}
					onSave={handleConfirmChannelQueueAction}
					saveText="Continuar"
				>
					<Grid item>
						<Typography variant="h4">
							Há mensagens na sua fila de envio. O que deseja fazer com elas?
						</Typography>
						<Divider orientation="horizontal" size={2} />

						<RadioGroup
							value={selectedQueueAction}
							onChange={handleSelectedFilter}
						>
							<Grid container>
								<Grid item>
									<Grid item>
										<FormControlLabel
											key={"clearQueuesMessages"}
											value={"clearQueuesMessages"}
											label={
												<>
													Limpar filas - <span style={{ fontWeight: 400, fontSize: 14 }}>Envio em massa, integração e bot</span>
												</>
											}
											classes={{
												label: classes.queueLabel
											}}
											control={<Radio disableRipple size="small" />} />

									</Grid>
								</Grid>
							</Grid>
							<Divider orientation="horizontal" size={2} />

							<Grid container>
								<Grid item>
									<FormControlLabel
										key={"keepQueuesMessages"}
										value={"keepQueuesMessages"}
										label={"Continuar envio"}
										classes={{
											label: classes.queueLabel
										}}
										control={<Radio disableRipple size="small" />} />

								</Grid>
							</Grid>
						</RadioGroup>
					</Grid>
				</ActionDialog>

				<QRCodeInstruction>
					<Grid
						container
						alignItems="center"
						justifyContent="center"
						direction="column"
						className={classes.generateQRCodeContent}
					>
						<Typography
							variant="h2"
							align="center"
							className={classes.generateQRCodeText}
						>
							GERE O QR CODE PARA CONECTAR O SEU WHATSAPP
						</Typography>

						<Divider orientation="horizontal" size={1} />

						<ArrowDownIcon
							className={classes.arrowDownIcon}
						/>

						<Divider orientation="horizontal" size={1} />

						<Button
							startIcon={(
								<GenerateQRCodeIcon />
							)}
							variant="contained"
							color="primary"
							onClick={hasEnqueuedMessages ? handleChannelQueue : handleGenerateQRCode}
							disabled={!isAbleGenerateQRCode}
						>
							GERAR QR CODE
						</Button>
					</Grid>
				</QRCodeInstruction>
			</Portlet>
		</>
	)
}

export default GenerateQRCode
