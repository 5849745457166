import React, { useRef, useState } from "react"
import { Grid, Button, CircularProgress } from "@material-ui/core"

import {
	ChatMessageBuilder
} from "@/components"

import { ChatMessageBuilderHandler, Message } from "@/components/ChatMessageBuilder"
import { BuildedMessage } from "@/protocols/messages"
import useStyles from "@/pages/Attendance/Chat/ConversationPanel/ConversationHeader/Snooze/Messages/styles"

type MessagesProps = {
	messages: BuildedMessage[],
	onSave: (messages: BuildedMessage[] | Message[]) => void | Promise<void>
}

const Messages: React.FC<MessagesProps> = ({
	messages,
	onSave
}) => {
	const [loading, setLoading] = useState(false)
	const chatMessageBuilderRef = useRef<ChatMessageBuilderHandler>(null)
	const classes = useStyles()

	const handleSave = async () => {
		setLoading(true)

		const updatedMessages = chatMessageBuilderRef.current?.getMessages() || messages
		chatMessageBuilderRef.current?.blur()
		await onSave(updatedMessages)

		setLoading(false)
	}

	return (
		<Grid container justifyContent="flex-end" spacing={2}>
			<Grid item xs={12}>
				<ChatMessageBuilder
					renderWithInitialMessage
					disabledInputs={["mentions", "custom-link"]}
					disabledMessage="Você não pode editar uma mensagem após o envio."
					ref={chatMessageBuilderRef}
					customClass={classes.chatMessageBuilderContainer}
					isUniqueMessage
				/>
			</Grid>

			<Grid item>
				<Button
					variant="contained"
					color="primary"
					onClick={handleSave}
					endIcon={loading && <CircularProgress size={20} />}
					disabled={loading}
				>
						SALVAR
				</Button>
			</Grid>
		</Grid>
	)
}

export default Messages
