import { datadogLogs } from "@datadog/browser-logs"

import StorageService from "@/services/Storage"
import ObservabilityService from "@/services/Observability"

type LogBody = Record<string, unknown>

/**
 * We're using the same log format defined in HubLib to simplify finding and correlating logs
 * related to a specific user across both the back-end and front-end.
 */
type DefaultLogBody = {
	attributes: {
		instanceId: number
	}
}

class LogService {
	info (message: string, body?: LogBody): void {
		if (ObservabilityService.canUseDatadogLogs) {
			datadogLogs.logger.info(message, { ...body, ...this.defaultData })
		}
	}

	error (error: Error, body?: LogBody): void {
		if (ObservabilityService.canUseDatadogLogs) {
			datadogLogs.logger.error(error.message, { ...body, ...this.defaultData }, error)
		}
	}

	private get defaultData (): DefaultLogBody {
		const rawInstanceId = StorageService.get<string>(StorageService.reservedKeys.INBOX_INSTANCE_ID)

		return {
			attributes: {
				instanceId: Number(rawInstanceId)
			}
		}
	}
}

export default new LogService()
