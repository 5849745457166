import { makeStyles } from "@material-ui/core"

import newColors from "@/styles/newColors"

const useStyles = makeStyles({
	root: {
		border: `1px solid ${newColors.grey[100]}`,
		borderRadius: 8,
		"&:before": {
			opacity: 0
		},
		"&.Mui-expanded": {
			margin: 0
		}
	},
	expandAccordionIcon: {
		backgroundColor: newColors.purple[200],
		borderRadius: 32,
		color: newColors.purple[600],
		padding: 4
	},
	expandedAccordion: {
		"&.MuiAccordionSummary-expandIcon.Mui-expanded": {
			transform: "rotate(90deg)"
		}
	},
	accordionSummary: {
		cursor: "auto !important",
		userSelect: "auto !important"
	},
	containerIcon: {
		width: 24,
		height: 24
	},
	wabaIconContainer: {
		borderRadius: 6,
		width: 34,
		height: 34,
		backgroundColor: newColors.green[500]
	},
	whatsappIcon: {
		color: newColors.grey[0]
	},
	channelInfoText: {
		fontWeight: 400,
		fontSize: 16,
		color: newColors.grey[800]
	},
	statusChip: {
		fontWeight: 400,
		fontSize: 16,
		padding: "4px 14px 4px 14px"
	},
	formLabel: {
		fontWeight: 400,
		fontSize: 12,
		color: newColors.grey[600]
	},
	saveButton: {
		fontWeight: 400,
		fontSize: 14,
		color: newColors.grey[0]
	}
})

export default useStyles
