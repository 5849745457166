import React from "react"
import CannyFeedbackSkeleton from "@/components/CannyFeedback/CannyFeedbackSkeleton"

const CannyFeedback = () => {
	const [loading, setLoading] = React.useState(true)

	React.useEffect(() => {
		window.Canny("render", {
			boardToken: "26e2e0d6-b2f6-143c-7a37-95e0984af255",
			basePath: null,
			ssoToken: null,
			theme: "light",
			onLoadCallback: () => {
				setLoading(false)
			}
		})
	})

	return (
		<>
			{/** The <Loading/> component is not being used here because the div with the data-canny attribute must always be "visible" to render and update the loading status */}
			{loading && (
				<CannyFeedbackSkeleton />
			)}
			<div data-canny ></div>
		</>
	)
}

export default CannyFeedback
