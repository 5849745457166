import React from "react"
import useCustomStyles from "@/styles/custom"
import { getRowsLabel } from "@/utils/table"
import {
	TableHead,
	Table,
	TableContainer,
	TableRow,
	TableCell,
	TableBody,
	TablePagination,
	Grid,
	Typography
} from "@material-ui/core"
import { Divider } from "@/components"
import { DefaultWhereDataType } from "@/utils/pagination"

export type AlignOptions = "right" | "left" | "center"

export type TableColumn<RowData> = {
	field: keyof RowData
	label: string
	align?: AlignOptions
	render?: (value: RowData[keyof RowData]) => React.ReactNode
}

type DataTableProps<RowData> = {
	columns: TableColumn<RowData>[]
	data: RowData[]
	page: number
	rowsPerPage: number
	totalRows: number
	onPaginationChange: (data: Partial<DefaultWhereDataType>) => void
}

const DataTable = <RowData, >(props: DataTableProps<RowData>) => {
	const {
		columns,
		data,
		page,
		rowsPerPage,
		totalRows,
		onPaginationChange
	} = props

	const customClasses = useCustomStyles()

	return (
		<>
			<TableContainer>
				<Table stickyHeader size="small">
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell
									key={String(column.field)}
									align={column.align || "left"}
									width={220}
								>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody className={customClasses.reportTableBodyText}>
						{data?.length > 0 &&
							data.map((row, rowIndex) => (
								<TableRow key={rowIndex}>
									{columns.map((column) => (
										<TableCell
											key={String(column.field)}
											align={column.align || "left"}
										>
											{column.render
												? column.render(row[column.field])
												: row[column.field] || "-"}
										</TableCell>
									))}
								</TableRow>
							))}
					</TableBody>
				</Table>

				{!data?.length && (
					<Grid item xs={12}>
						<Divider size={3} orientation="horizontal" />

						<Typography align="center" variant="h2">
							Nenhum resultado encontrado
						</Typography>
					</Grid>
				)}
			</TableContainer>

			<TablePagination
				rowsPerPageOptions={[20, 50, 100, 200]}
				component="div"
				count={totalRows}
				rowsPerPage={rowsPerPage || 0}
				page={page || 0}
				onPageChange={(_, page) => onPaginationChange({ page: page })}
				onRowsPerPageChange={({ target }) => {
					onPaginationChange({ rowsPerPage: +target.value })
					onPaginationChange({ page: 0 })
				}}
				labelRowsPerPage={"Resultados por página:"}
				labelDisplayedRows={(tableData) =>
					getRowsLabel(tableData, rowsPerPage)
				}
			/>
		</>
	)
}

export default DataTable
