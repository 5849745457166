import React from "react"
import { Chip } from "@material-ui/core"
import useStyles from "@/components/FeatureStatusChip/styles"

type FeatureStatus = "Novo"

type FeatureStatusChipProps = {
	status: FeatureStatus
}

const FeatureStatusChip: React.FC<FeatureStatusChipProps> = (props) => {
	const {
		status
	} = props

	const classes = useStyles()

	return (
		<Chip
			size="small"
			label={status}
			className={classes.container}
		/>
	)
}

export default FeatureStatusChip
