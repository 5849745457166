import React from "react"
import {
	FormControlLabel,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	TextField,
	Typography
} from "@material-ui/core"

import { SnoozeData, SnoozeTime, SnoozeTimeOptions } from "@/protocols/snooze"
import { getSnoozeTimeOptionContent, snoozeMenuOptions } from "@/utils/snooze"
import { BuildedMessage } from "@/protocols/messages"

import Messages from "@/pages/Attendance/Chat/ConversationPanel/ConversationHeader/Snooze/Messages"

type CreateNewSnoozeContentProps = {
	messages: BuildedMessage[]
	sendMessageOnWakeUp: boolean
	handleSendMessageOnWakeUp: (data: boolean) => void
	handleSetMessages: (messages: BuildedMessage[]) => void
	handleSetSnoozeTime: (data: SnoozeTime) => void
	handleChangeSnoozeNote: (note: string) => void
	snoozeData: SnoozeData
}

const CreateNewSnoozeContent: React.FC<CreateNewSnoozeContentProps> = (props) => {
	const {
		messages,
		handleSetMessages,
		sendMessageOnWakeUp,
		handleSendMessageOnWakeUp,
		snoozeData,
		handleSetSnoozeTime,
		handleChangeSnoozeNote
	} = props

	return (
		<>
			<Grid item xs={12}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<InputLabel>Soneca por:</InputLabel>
						<Grid container spacing={2}>
							<Grid item xs={3}>
								<TextField
									defaultValue={snoozeData.snoozeTime.time}
									variant="outlined"
									type="number"
									fullWidth
									onChange={({ target }) => handleSetSnoozeTime({
										...snoozeData.snoozeTime,
										time: Number(target.value)
									})}
									InputProps={{ inputProps: { min: 1, max: 99 } }}
								/>
							</Grid>
							<Grid item xs={9}>
								<Select
									variant="outlined"
									fullWidth={true}
									defaultValue={snoozeData.snoozeTime.timeOption.type}
									onChange={({ target }) => handleSetSnoozeTime({
										...snoozeData.snoozeTime,
										timeOption: getSnoozeTimeOptionContent(target.value as SnoozeTimeOptions)
									}) }
								>
									{Object.keys(snoozeMenuOptions).map((option, index) => {
										return (
											<MenuItem
												key={index}
												value={option}
											>
												{snoozeMenuOptions[option as SnoozeTimeOptions].title}
											</MenuItem>
										)
									})}
								</Select>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Typography
							color="textPrimary"
						>
                            Deseja inserir uma observação?
						</Typography>
						<TextField
							value={snoozeData.snoozeNote}
							onChange={ ({ target }) => { handleChangeSnoozeNote(target.value) } }
							variant="outlined"
							placeholder="Será mostrada apenas para você ao despertar do soneca (opcional)"
							color="primary"
							multiline
							fullWidth
							rows={2}
						/>
					</Grid>
				</Grid>
			</Grid>

			<Grid item xs={12}>
				{/*  By default, the FormControlLabel has a remaining negative margin of -11, here I changed it to -6 so that it fits perfectly inside the container. */}
				<FormControlLabel style={{ marginLeft: -6 }} control={<Switch size="small" checked={sendMessageOnWakeUp} onChange={({ target }) => handleSendMessageOnWakeUp(target.checked)} />} label="Enviar uma mensagem ao despertar do soneca" />
			</Grid>
			{sendMessageOnWakeUp && (
				<Grid item xs={12}>
					<Messages messages={messages || []} onSave={(messages) => handleSetMessages(messages) }/>
				</Grid>
			)}
		</>
	)
}

export default CreateNewSnoozeContent
