import { redirectToInstanceChangedPage } from "@/utils/url"

class StorageService {
	reservedKeys = {
		INBOX_INSTANCE_ID: "inbox-instance-id",
		INBOX_ADMIN_ID: "inbox-admin-id",
		INBOX_CHAT_MESSAGE_SKETCH: "inbox-chat-message-sketch",
		INBOX_WABA_WALLET_BALANCE_NOTIFICATION: "inbox-waba-wallet-balance-notification"
	}

	constructor () {
		const inboxInstanceIdReservedKey = this.reservedKeys.INBOX_INSTANCE_ID

		window.addEventListener("storage", function (event) {
			const isChangeInstanceIdEvent = event.key === inboxInstanceIdReservedKey
			const alreadyHadValueDefined = Boolean(event.oldValue)
			const isChangingToDifferentValue = event.oldValue !== event.newValue

			if (isChangeInstanceIdEvent && alreadyHadValueDefined && isChangingToDifferentValue) {
				redirectToInstanceChangedPage()
			}
		})
	}

	set<ExpectedData> (key: string, data: ExpectedData): void {
		const stringData = JSON.stringify(data)

		const encodedData = btoa(stringData)

		localStorage.setItem(key, encodedData)
	}

	get<ExpectedData> (key: string): ExpectedData | null {
		const encodedData = localStorage.getItem(key)

		if (!encodedData) {
			return null
		}

		const stringData = atob(encodedData)

		const data: ExpectedData = JSON.parse(stringData)

		return data
	}

	delete (key: string): void {
		localStorage.removeItem(key)
	}

	mountKey (baseKey: string, identifier: string): string {
		return `${baseKey}-${identifier}`
	}
}

export default new StorageService()
