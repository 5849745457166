import { datadogRum } from "@datadog/browser-rum"
import { datadogLogs } from "@datadog/browser-logs"

import environmentConfig from "@/config/environment"
import observabilityConfig from "@/config/observability"

import ErrorHandlerService from "@/services/ErrorHandler"
import HardCoded from "@/services/HardCoded"

type DefaultDatadogObservabilityConfig = {
	clientToken: string
	site: "us5.datadoghq.com"
	service: string
	env: NodeJS.ProcessEnv["NODE_ENV"]
}

type TracingUserData = {
	id: number
	instanceId: number
	name: string
	email: string
}

class ObservabilityService {
	setup (): void {
		try {
			if (this.canUseDatadogTracing) {
				this.setupDatadogTracing()
			}

			if (this.canUseDatadogLogs) {
				this.setupDatadogLogs()
			}
		} catch (error) {
			ErrorHandlerService.handle(error as Error)
		}
	}

	setTracingUserData (tracingUserData: TracingUserData): void {
		try {
			if (this.canUseDatadogTracing) {
				const { id, ...otherData } = tracingUserData

				datadogRum.setUser({ id: String(id), ...otherData })
			}
		} catch (error) {
			ErrorHandlerService.handle(error as Error)
		}
	}

	get canUseDatadogLogs (): boolean {
		return this.isThereAnyValidDatadogConfig && HardCoded.checkFeatureFlag("datadogLogs")
	}

	private get canUseDatadogTracing (): boolean {
		return this.isThereAnyValidDatadogConfig && HardCoded.checkFeatureFlag("datadogTracing")
	}

	private get isThereAnyValidDatadogConfig (): boolean {
		return Boolean(observabilityConfig.datadogApplicationId) && Boolean(observabilityConfig.datadogClientToken)
	}

	private setupDatadogTracing (): void {
		datadogRum.init({
			...this.defaultDatadogObservabilityConfig,
			applicationId: String(observabilityConfig.datadogApplicationId),
			sessionSampleRate: 100,
			sessionReplaySampleRate: 100,
			trackResources: true,
			trackLongTasks: true,
			trackUserInteractions: true,
			defaultPrivacyLevel: "mask-user-input"
		})
	}

	private setupDatadogLogs (): void {
		datadogLogs.init({
			...this.defaultDatadogObservabilityConfig,
			forwardErrorsToLogs: false,
			sessionSampleRate: 100
		})
	}

	private get defaultDatadogObservabilityConfig (): DefaultDatadogObservabilityConfig {
		return {
			clientToken: String(observabilityConfig.datadogClientToken),
			site: String(observabilityConfig.datadogSite) as DefaultDatadogObservabilityConfig["site"],
			service: String(observabilityConfig.service),
			env: environmentConfig.env
		}
	}
}

export default new ObservabilityService()
